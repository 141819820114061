import { useMemo } from "react";
import {
  flowDataSeries,
  flowDataUnits
} from "../../state/recoil";
import {
  useRecoilValue,
} from "recoil";

import SeriesGraph from "../../components/SeriesGraph";

import { DownloadButton } from './DownloadButton';

export const Graph = () => {
  const data = useRecoilValue(flowDataSeries);
  const units = useRecoilValue(flowDataUnits);

  // // TODO Dry?
  // // Get array of points with a dry reading to display, IF we have screen base
  // const pointsWithDryReadings = useMemo(() => {
  //   let dataPoints = [];

  //   // data.forEach((series) => {
  //   //   if (wlData[series.index]?.guides?.screenBot) {
  //   //     series.data.forEach((point) => {
  //   //       if (point.isDry) {
  //   //         dataPoints = [
  //   //           ...dataPoints,
  //   //           {
  //   //             series: series.name,
  //   //             id: point.ags_data_id,
  //   //             x: point.x
  //   //           },
  //   //         ];
  //   //       }
  //   //     });
  //   //   }
  //   // });

  //   return dataPoints;
  // }, [data]);

  // Get an array of points with comment threads
  const pointsWithComments = useMemo(() => {
    let dataPoints = [];
    data.forEach((dataSet) => {
      dataSet.data.forEach((point) => {
        if (point.comment_count) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });

    return dataPoints;
  }, [data]);

  if (data && data.length && data.find(series => series.data && series.data.length)) {
    return (
      <SeriesGraph
        dataType="flowData"
        data={data}
        guides={[]}
        yTitle={`Flow Data (${units})`}
        canSelectPoint={true}
        // TODO Dry?
        // pointsWithDryReadings={pointsWithDryReadings}
        pointsWithComments={pointsWithComments}
      ></SeriesGraph>
    );
  } else {
    return <></>;
  }
};
