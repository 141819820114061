import './downloadButton.css';

import React, { useState } from 'react';
import fileDownload from "js-file-download";
import { stringify } from "qs";

import {
    Button,
    Tooltip
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import api from "../../lib/api";
import logUserActivity from "../../common/user-activity-logging";
import { useAuth } from '../auth-provider/auth-provider';

export const DownloadButton = ({
    componentName,
    disabled,
    params,
    tooltipTitle,
    url
}) => {
    const auth = useAuth();
    const [isDownloading, setIsDownloading] = useState(false);

    const handleButtonClicked = async () => {
        try {
            if (disabled) {
                if (isDownloading) {
                    return setIsDownloading(false);
                }
                return;
            }

            setIsDownloading(true);

            const response = await api.get(url, {
                responseType: 'blob',
                params,
                paramsSerializer: params => {
                    return stringify(params)
                }
            });
            const filename = response.headers['content-disposition'].split('"')[1] + '.csv';
            fileDownload(response.data, filename);
            setIsDownloading(false);
        }
        catch (err) {
            auth.logout();
        }
    };

    return (
        <div className="ml-3">
            <Tooltip title={tooltipTitle}>
                <Button
                    disabled={disabled}
                    className={'hda-btn-download-csv'}
                    variant="outlined"
                    onClick={handleButtonClicked}
                >
                    <FileDownloadIcon />
                </Button>
            </Tooltip>
            {/* <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Warning: no available coordinates</DialogTitle>

                <DialogContent dividers>
                    <DialogContentText color="main">
                        <b>{idsText}</b> {doesDoText} not have coordinates available, and cannot be georeferenced in the geospatial Groundwater Risk App (GWR App). It may be that this borehole has other aliases with coordinates available. Do you want to continue to the geospatial GWR App?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinueNavigation} color="primary" autoFocus variant="contained">Continue</Button>
                </DialogActions>
            </Dialog> */}
        </div>
    );
}
