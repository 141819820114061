import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useRef } from "react";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Feature } from 'flagged';
import Typography from "@mui/material/Typography";
import config from "../../../lib/config";
import logo from "./logo.png";
import styles from "../disclaimer.module.css";
import { useAuth } from "../../auth-provider/auth-provider";

import { WaterLevelsCaveats } from "../caveats/WaterLevelsCaveats";
import { InterimWaterLevelsCaveats } from "../caveats/InterimWaterLevelsCaveats";
import { FlowDataCaveats } from "../caveats/FlowDataCaveats";
import { WaterChemistryCaveats } from "../caveats/WaterChemistryCaveats";
import { ChemconnectCaveats } from "../caveats/ChemconnectCaveats";

export default function HS2Disclaimer({ open, appInfo, agreed }) {
  const { user } = useAuth();

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString();
    }
  }

  return (
    <DialogContent dividers={true}>
      <DialogContentText
        component={"div"}
        id="scroll-dialog-description"
        tabIndex={-1}
      >
        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          <img
            style={{ display: "block", margin: "0 auto 16px auto", maxHeight: "200px" }}
            src={logo}
            alt={"logo"}
          ></img>
          <p style={{ marginTop: 0 }}>
            This dashboard is a visualisation tool for the HS2 GI &amp;
            Water Management Database.
          </p>
          <p>
            Prior to use of this data visualisation tool please agree to the
            below general terms of service:
          </p>
          <ol start="1" type="1">
            <li>
              All data supplied is delivered “as is”. The Information is
              licensed 'as is' and the Information Provider and/or Licensor
              excludes all representations, warranties, obligations and
              liabilities in relation to the Information to the maximum
              extent permitted by law.
            </li>
            <li>
              The Information Provider and/or Licensor are not liable for
              any errors or omissions in the information and shall not be
              liable for any loss, injury or damage of any kind caused by
              its use. The Information Provider does not guarantee the
              continued supply of the Information.
            </li>
          </ol>
          <div>
            <ol style={{ listStyle: "none" }}>
              <li>
                API version No.: <strong>{appInfo.api_version}</strong>
              </li>
              <li>
                Dashboard version No.: <strong>{appInfo.client_version}</strong>
              </li>
              <li>
                Current load: <strong>{appInfo.current_load_number}</strong>
              </li>
            </ol>
          </div>
          <p style={{ marginBottom: "8px" }}>You can find the full terms of service below.</p>
        </Typography>

        {user?.allow_waterlevel && <WaterLevelsCaveats appInfo={appInfo} />}
        {user?.allow_waterlevel && <InterimWaterLevelsCaveats appInfo={appInfo} />}
        {user?.allow_flowdata && <FlowDataCaveats appInfo={appInfo} />}
        {user?.allow_chemistry && <WaterChemistryCaveats appInfo={appInfo} />}
        {user?.allow_chemistry && <ChemconnectCaveats appInfo={appInfo} />}

        <Feature name="rainfall">
          <Accordion sx={{ mt: 1 }} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography><strong>Acknowledgments</strong></Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              <Typography component={"div"}>
                <p className="mt-0">
                  The EKFB Hydrological Data Application uses Environment
                  Agency rainfall data from the real-time data API (Beta).
                  This is provided as open data under the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                  >
                    Open Government Licence.
                  </a>
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Feature>
        <Accordion sx={{ mt: 1 }} className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography><strong>Privacy Terms and conditions</strong></Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <Typography component={"div"}>
              <strong>Your Rights Under UK GDPR:</strong>
              <ol start="1" type="1">
                <li><strong>Right to Access:</strong> You can request access to the personal data we hold about you.</li>
                <li><strong>Right to Rectification:</strong> If you believe the information, we hold is inaccurate or incomplete, you have the right to correct it.</li>
                <li><strong>Right to Erasure:</strong> You can request the deletion of your personal data under certain circumstances.</li>
                <li><strong>Right to Restriction of Processing:</strong> You have the right to limit how we use your data.</li>
                <li><strong>Right to Data Portability:</strong> You can request a copy of your data in a commonly used format.</li>
                <li><strong>Right to Object:</strong> You can object to the processing of your personal data for certain purposes.</li>
              </ol>
              <strong>Information We Collect</strong>
              <p>We collect and process the following information to ensure security, performance and reliability of the application:</p>
              <ol start="1" type="1">
                <li>Name</li>
                <li>Email</li>
                <li>Login details</li>
                <li>IP addresses for actions</li>
                <li>Activity timestamps for login, selection, closure, logout, download, reset, signup, and agreement</li>
              </ol>
              <strong>Your Consent Matters:</strong>
              <p>By continuing to use our services, you agree to the terms outlined above and acknowledge that you have read and understood our data usage practices. For further information please see our Privacy Policy.</p>
              <p>If you have any questions or concerns, or wish to notify us of any requests related to the above rights, please contact us at <a href="mailto:ekfbhda@siorconsulting.com">ekfbhda@siorconsulting.com</a>.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          {agreed !== true &&
            <p>
              Click <strong>AGREE</strong> to view the dashboard, by clicking
              here, you are agreeing to the terms of service.
            </p>
          }
          <p>Credits:</p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <a href="https://gwp.uk.com/">GWP Consultants LLP</a>
            </li>
            <li>
              <a href="https://siorconsulting.com/">
                S&iacute;or Consulting Ltd
              </a>
            </li>
          </ul>
        </Typography>
      </DialogContentText>
    </DialogContent>
  )
}
