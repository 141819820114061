import {
  Box,
} from "@mui/material";
import { useMemo } from "react";
import {
  chemistryDataState,
  chemistryDataTypeState,
  chemistryNullValuesState,
  selectedDeterminantSelector,
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue,
} from "recoil";

import { DownloadButton } from './DownloadButton';

import SeriesGraph from "../../components/SeriesGraph";
import config from "../../lib/config";

export const Graph = () => {
  const chemistryData = useRecoilValue(chemistryDataState);
  const type = useRecoilValue(chemistryDataTypeState);
  const selectedDeterminant = useRecoilValue(selectedDeterminantSelector);

  const [nullValues, setNullValues] = useRecoilState(chemistryNullValuesState);

  const pointsWithComments = useMemo(() => {
    let dataPoints = [];

    // TODO: duplicate code also used in waterlevels
    chemistryData.forEach((dataSet, seriesIndex) => {
      dataSet.data.forEach((point, pointIndex) => {
        if (point.comment_count) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });
    return dataPoints;
  }, [chemistryData]);

  const pointsUngrouped = useMemo(() => {
    let dataPoints = [];

    // TODO: duplicate code also used in waterlevels
    chemistryData.forEach((dataSet) => {
      dataSet.data.forEach((point) => {
        if (!point.is_grouped) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              unit: point.unit,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });
    return dataPoints;
  }, [chemistryData]);

  return (
    <Box>
      {chemistryData && pointsWithComments && pointsUngrouped && (
        <SeriesGraph
          dataType={"chemistry"}
          data={chemistryData}
          isReversed={false}
          guides={[]}
          yTitle={
            config.chemistryDeterminantLabelMap[selectedDeterminant] ||
            chemistryData[0]?.legend?.unit
          }
          min={0}
          canSelectPoint={type === "all"}
          pointsWithComments={pointsWithComments}
          pointsWithAnnotations={pointsUngrouped}
          omitNull={nullValues === "omit" ? true : false}
        ></SeriesGraph>
      )}
    </Box>
  );
};
