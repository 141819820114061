import { Alert, AlertTitle, Button, CircularProgress, Grid, Typography } from "@mui/material";
// import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import featureFlags from "../feature-flag.json";
import api from "../lib/api";
import PasswordForm from "./admin/user/password-form";

export default function PasswordChangePage() {
    const { id } = useParams();
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const getPasswordReset = async () => {
            try {
                const passwordReset = (await api.get(`/user/password-reset/${id}`)).data;
                if (passwordReset && passwordReset.id) {
                    setIsReady(true);
                } else {
                    setIsExpired(true);
                }
            } catch (err) {
                // if (featureFlags.sentry) {
                //     Sentry.captureException(err);
                // }
                setIsExpired(true);
            }
        }
        getPasswordReset().catch(console.log);
    }, [id])


    const handleFormSubmitted = async (value) => {
        setIsError(false);
        setIsExpired(false);
        setIsSuccess(false);
        setIsLoading(true);

        try {
            const user = await api.post('/user/reset-password', {
                id,
                password: value.password
            });
            if (user.data && user.data.id) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }
        } catch (err) {
            // if (featureFlags.sentry) {
            //     Sentry.captureException(err);
            // }
            setIsError(true);
        }

        setIsLoading(false);
    }

    return (
        <Grid justifyContent="left" container>
            <Grid item xs={12}>
                <Typography variant="h2">Change password</Typography>
            </Grid>
            <Grid className="pt-12" item xs={12} md={4}>
                {isSuccess &&
                    <Alert severity="success">
                        <AlertTitle>Password changed</AlertTitle>
                        Your password has been changed successfully.
                        <br />
                        You can now <Link to="/login"><strong>Login</strong></Link>.
                    </Alert>
                }
                {isError &&
                    <Alert severity="error">
                        <AlertTitle>Password not changed</AlertTitle>
                        Password change failed.
                    </Alert>
                }
                {isExpired &&
                    <Alert severity="error">
                        <AlertTitle>Link expired</AlertTitle>
                        The link has expired after certain amount of time.
                    </Alert>
                }
                {isReady &&
                    <div className="mt-4">
                        <PasswordForm defaultValues={{}} onSubmit={handleFormSubmitted} />
                        <Grid justifyContent="right" container>
                            <Grid className="pt-4">
                                <Button
                                    onClick={() => { navigate('/login') }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    form="password-form"
                                    variant="contained"
                                    disabled={isLoading || isSuccess}
                                >
                                    Change {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                }
            </Grid>
        </Grid>
    );
}
