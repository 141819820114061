import { atom, selector } from "recoil";

import api from "../lib/api";
import featureFlags from "../feature-flag.json";
import logUserActivity from "../common/user-activity-logging";

// Main recoil imports
import {
  selectedDataPointFamily,
  visibleSeriesState
} from './recoil';

export const flowDataPipesState = selector({
  key: 'flowDataPipes',
  get: async ({ get }) => {
    const response = await api.get('flowdata/pipes');
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const selectedBoreholesFlowDataState = atom({
  key: "selectedBoreholesFlowData",
  default: [],
});

// selectedBoreholesFlowDataSelector
export const selectedBoreholesFlowDataSelector = selector({
  key: "selectedBoreholesFlowDataSelector",
  get: ({ get }) => {
    return get(selectedBoreholesFlowDataState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily('flowData'), null);

    const visibleSeries = newValue.map((borehole) => ({
      name: borehole.borehole_pipe_id,
      visible: true,
    }));

    // Add extra series for the rainfall data when only selecting 1 borehole
    if (newValue.length === 1) {
      visibleSeries.push({ name: 'Rainfall', visible: true });
    }

    set(visibleSeriesState('flowData'), visibleSeries);

    set(selectedBoreholesFlowDataState, newValue);

    logUserActivity({
      action: 'BOREHOLE_SELECTED',
      component: 'flowData',
      data: newValue,
    });
  },
});

export const flowDataDataType = atom({
  key: 'flowDataDataType',
  default: "all",
});

// Fetch location data for pipe ID
export const flowDataLocationData = selector({
  key: 'flowDataLocationData',
  get: async ({ get }) => {
    const boreholes = get(selectedBoreholesFlowDataSelector);
    if (!boreholes) {
      return [];
    }
    const promises = Promise.all(
      boreholes.map((borehole) => api.get(`location/${borehole.borehole_pipe_id}`))
    );
    const responses = await promises;
    return responses.map((response) => response.data);
  },
});

export const flowDataUnits = atom({
  key: "flowDataUnits",
  default: "l/ph",
});

export const flowDataData = selector({
  key: 'flowDataData',
  get: async ({ get }) => {
    const selectedBoreholes = get(selectedBoreholesFlowDataSelector);
    const type = get(flowDataDataType);

    if (!selectedBoreholes) {
      return [];
    }

    const promises = Promise.all(
      selectedBoreholes.map((borehole) =>
        api.get(
          `flowdata/${type}/series/${encodeURIComponent(
            borehole.borehole_pipe_id
          )}`,
          { params: { pipeType: borehole.pipe_type } }
        )
      )
    );
    const responses = await promises;

    return responses.map((item) => item.data);
  },
});

// Flow Data series
export const flowDataSeries = selector({
  key: 'flowDataSeries',
  get: ({ get }) => {
    const data = get(flowDataData);
    const selectedBoreholes = get(selectedBoreholesFlowDataSelector);

    if (!data) {
      return null;
    }

    // Process flow data
    let seriesData = data.map((item, index) => {
      const modifiedData = [];

      // TODO dry readings? (but not using pipe base)
      item.series.forEach(point => {
        modifiedData.push(point);
        //   modifiedData.push({
        //     ...point,
        //     y: point.isDry ? (boreholePipeBase !== undefined ? boreholePipeBase : NaN) : point.y
        //   });
      });

      return {
        name: selectedBoreholes[index].borehole_pipe_id,
        data: modifiedData,
        index,
      };
    });

    // Include rainfall data if the feature flag is enabled
    if (featureFlags.rainfall) {
      const rainfallData = get(flowDataNearestRainfallStationData);
      if (selectedBoreholes.length === 1 && rainfallData?.length) {
        seriesData = [
          ...seriesData,
          {
            data: rainfallData.map(item => ({
              y: item.value,
              x: item.date,
            })),
            name: 'Rainfall',
            index: 1,
          },
        ];
      }
    }

    return seriesData;
  },
});

// Threads
export const flowDataDataPointThreads = selector({
  key: "flowDataThreads",
  get: ({ get }) => {
    const datapoint = get(selectedDataPointFamily('flowData'));
    if (!datapoint || !datapoint.threads) {
      return [];
    }
    return datapoint.threads;
  },
});

// Nearest rainfall station to selected borehole
export const flowDataNearestRainfallStation = selector({
  key: "FDNearestStation",
  get: async ({ get }) => {
    const selected = get(selectedBoreholesFlowDataSelector);
    if (selected.length !== 1) {
      return null;
    }
    try {
      const { data: stations } = await api.get(
        "/rainfall/nearby_stations/" + selected[0].borehole_pipe_id.split('#')[0]
      );
      return stations[0];
    }
    catch (e) {
      return null;
    }
  },
});

// Fetch the rainfall data for the nearest station
export const flowDataNearestRainfallStationData = selector({
  key: 'FDNearestStationRainfall',
  get: async ({ get }) => {
    const nearestStation = get(flowDataNearestRainfallStation);
    const location = get(flowDataLocationData);
    if (!nearestStation || !location) {
      return null;
    }

    const { data: readings } = await api.get(
      "/rainfall/station/" +
      nearestStation.stationReference +
      "/readings/dailyTotal"
    );
    return readings;
  },
});
