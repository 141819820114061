import {
  Autocomplete,
  TextField,
  Tooltip
} from "@mui/material";
import { useEffect } from "react";
import {
  ccchemicalBoreholesState as chemicalBoreholesState,
  ccselectedBoreholesChemistrySelector as selectedBoreholesChemistrySelector,
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValueLoadable,
} from "recoil";

import { useSearchParams } from "react-router-dom";

export const BoreholeSelect = ({ className }) => {
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState);
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesChemistrySelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedBoreholes = searchParams.get('boreholes')?.split(',');
    if (preselectedBoreholes) {
      const filteredPreselectedBoreholes = boreholes.getValue().filter(borehole => preselectedBoreholes?.includes(borehole));
      if (filteredPreselectedBoreholes?.length > 0) {
        setSelectedBoreholes(filteredPreselectedBoreholes);
      }
    }
  }, []);

  const handleBoreholeChange = async (event, value) => {
    if (value !== null) {
      setSelectedBoreholes(value);
    }
  };

  return (
    <Tooltip
      title={"List only contains boreholes which has chemistry data available"}
    >
      <Autocomplete
        className={className}
        multiple={true}
        getOptionLabel={(option) => (option ? option : "")}
        disablePortal
        id="combo-box-demo"
        value={selectedBoreholes}
        options={boreholes.getValue()}
        renderInput={(params) => <TextField {...params} label="Monitoring Point ID" />}
        onChange={handleBoreholeChange}
      />
    </Tooltip>
  );
};
