import {
  Box,
  Typography
} from "@mui/material";
import { Stack } from "@mui/system";

import config from '../../lib/config';
import { useRecoilValue } from "recoil";
import { frontEndSettingsState } from "../../state/recoil-frontEndSettings";

export const MaintenancePage = () => {
  const frontEndSettings = useRecoilValue(frontEndSettingsState);

  if (!frontEndSettings.maintenanceMode) {
    return null;
  }

  const {
    title: maintenanceTitle,
    subtitle: maintenanceSubtitle,
    text: maintenanceText
  } = frontEndSettings.maintenanceMode;

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={3}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          marginBottom={3}
        >
          <Typography variant="h2">{maintenanceTitle}</Typography>
        </Box>

        <Stack spacing={2}>
          <Typography fontWeight={"bold"}>{maintenanceSubtitle}</Typography>
          <Typography>{maintenanceText}</Typography>
        </Stack>
      </Box>
    </Box>
  );
};
