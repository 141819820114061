import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import {
  waterLevelsDataTypeSelector,
  waterLevelsGraphReversed,
  waterLevelsUnitsSelector
} from "../../state/recoil";
import {
  useRecoilState
} from "recoil";

export const Filters = () => {
  const [dataType, setDataType] = useRecoilState(waterLevelsDataTypeSelector);
  const [units, setUnits] = useRecoilState(waterLevelsUnitsSelector);
  const [graphReversed, setGraphReversed] = useRecoilState(waterLevelsGraphReversed);

  const handleUnitsChange = (event, value) => {
    if (value !== null) {
      setGraphReversed(event.target.value !== "maod");
      setUnits(event.target.value);
    }
  };

  const handleDateTypeChange = (event, value) => {
    setDataType(value.props.value);
  };

  const handleGraphReverse = async (event, value) => {
    if (event.target.value !== null) setGraphReversed(event.target.value);
  };

  return (
    // <Card>
    //   <CardContent>
        <Box>
          <FormGroup row>
            <FormControl sx={{ m: 1, ml: 0 }}>
              <InputLabel id={"dataType"}>Graph type</InputLabel>

              <Select
                label={"Graph type"}
                id={"dataType"}
                value={dataType}
                onChange={handleDateTypeChange}
              >
                <MenuItem value={"dailyAverage"}>Daily Average</MenuItem>
                <MenuItem value={"all"}>All readings</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"units"}>Units</InputLabel>
              <Select
                label={"Reverse Y axis"}
                id={"units"}
                value={units}
                onChange={handleUnitsChange}
              >
                <MenuItem value={"mbgl"}>mBGL</MenuItem>
                <MenuItem value={"maod"}>mAOD</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"reversed"}>Reverse Y axis</InputLabel>
              <Select
                disabled={units === "maod"}
                label={"Reverse Y axis"}
                id={"reversed"}
                value={graphReversed}
                onChange={handleGraphReverse}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
    //   </CardContent>
    // </Card>
  );
};
