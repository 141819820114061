import {
  Box,
  Button,
  Divider,
  Link as MaterialLink,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import config from '../lib/config';

import { Link } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { NestedMenuItem } from 'mui-nested-menu';
import { disclaimerOpenAtom, frontEndSettingsState } from "../state/recoil";
import { useAuth } from "../components/auth-provider/auth-provider";
import { useRecoilState, useRecoilValue } from "recoil";
import { useState } from "react";
import { userRoles } from "../lib/user-roles";

const PageLayout = ({ page, title, ...props }) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [disclaimerOpen, setDisclaimerOpen] = useRecoilState(disclaimerOpenAtom);

  const frontEndSettings = useRecoilValue(frontEndSettingsState);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!frontEndSettings.menuAccess) {
    return null;
  }

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={3}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          marginBottom={3}
        >
          <Typography variant="h2">{title}</Typography>

          <Button onClick={handleClick}>
            <MenuIcon fontSize="large"></MenuIcon>
          </Button>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>

            {frontEndSettings.menuAccess.waterLevels && user?.allow_waterlevel && <MaterialLink
              component={Link}
              to={"/waterLevels"}
              underline="none"
            >
              <MenuItem>Water Levels</MenuItem>
            </MaterialLink>}

            {frontEndSettings.menuAccess.interimWaterLevels && <MaterialLink
              component={Link}
              to={"/interimWaterLevels"}
              underline="none"
            >
              <MenuItem>Interim Water Levels</MenuItem>
            </MaterialLink>}

            {
              (frontEndSettings.menuAccess.waterLevels ||
                frontEndSettings.menuAccess.interimWaterLevels) &&
              <Divider />
            }

            {frontEndSettings.menuAccess.flowData && user?.allow_flowdata && <>
              <MaterialLink
                component={Link}
                to={"/flowData"}
                underline="none"
              >
                <MenuItem>Flow Data</MenuItem>
              </MaterialLink>

              <Divider />
            </>}

            {frontEndSettings.menuAccess.waterChemistry && user?.allow_chemistry && <MaterialLink
              component={Link}
              to={"/waterChemistry"}
              underline="none"
            >
              <MenuItem>Water Chemistry</MenuItem>
            </MaterialLink>}

            {/* {frontEndSettings.menuAccess.interimWaterChemistry} */}

            {frontEndSettings.menuAccess.chemconnectData && user?.allow_chemistry && <MaterialLink
              component={Link}
              to={"/chemconnect"}
              underline="none"
            >
              <MenuItem>Chemconnect Data</MenuItem>
            </MaterialLink>}

            {(frontEndSettings.menuAccess.waterChemistry ||
              frontEndSettings.menuAccess.interimWaterChemistry ||
              frontEndSettings.menuAccess.chemconnectData) && <Divider />}

            <MaterialLink
              component={Link}
              to={"/faq"}
              underline="none"
            >
              <MenuItem>FAQ</MenuItem>
            </MaterialLink>

            <MaterialLink
              onClick={() => {
                if (!disclaimerOpen) {
                  setDisclaimerOpen(true);
                }
              }}
              underline="none"
            >
              <MenuItem>Terms and Conditions</MenuItem>
            </MaterialLink>

            <MaterialLink component={Link} to={"/logout"} underline="none">
              <MenuItem divider={user.role === userRoles.admin || user.role === userRoles.platform_admin}
                sx={{ display: "flex", justifyContent: "space-between" }}>
                Log Out
                <Logout />
              </MenuItem>
            </MaterialLink>

            <MaterialLink underline="none">
              {(user.role === userRoles.admin || user.role === userRoles.platform_admin) &&
                <NestedMenuItem
                  label="Admin"
                  parentMenuOpen={open}
                  disableRipple
                >
                  <MaterialLink
                    component={Link}
                    to={"/admin/user"}
                    underline="none"
                  >
                    <MenuItem> User</MenuItem>
                  </MaterialLink>
                </NestedMenuItem>}
            </MaterialLink>

          </Menu>
        </Box>
        {page}
      </Box>
    </Box>
  );
};

export default PageLayout;
