import { useMemo } from "react";
import {
  waterLevelsDataType,
  waterLevelsGraphReversed,
  waterLevelsGuides,
  waterLevelsMax,
  waterLevelsMin,
  waterLevelsSeries,
  waterLevelsUnitsSelector
} from "../../state/recoil";
import {
  useRecoilValue
} from "recoil";

import SeriesGraph from "../../components/SeriesGraph";
import config from "../../lib/config";

import { DownloadButton } from './DownloadButton';

export const Graph = () => {
  const guides = useRecoilValue(waterLevelsGuides);
  const data = useRecoilValue(waterLevelsSeries);
  const min = useRecoilValue(waterLevelsMin);
  const max = useRecoilValue(waterLevelsMax);
  const reversed = useRecoilValue(waterLevelsGraphReversed);
  const units = useRecoilValue(waterLevelsUnitsSelector);
  const type = useRecoilValue(waterLevelsDataType);

  const getColor = (name) => {
    if (name === "ground") {
      return config.colors.groundLevel;
    }
    if (name === "screenTop" || name === "screenBot") {
      return config.colors.pipe;
    }
    return "gray";
  };

  const guidesArray = Object.keys(guides || {})
    .filter((key) => ["ground", "screenTop", "screenBot"].includes(key))
    .map((key) => {
      return {
        value: parseFloat(guides[key]),
        color: getColor(key),
        name: key,
      };
    });

  // Get array of points with a dry reading to display, IF we have screen base
  const pointsWithDryReadings = useMemo(() => {
    let dataPoints = [];

    data.forEach((series) => {
      series.data.forEach((point) => {
        if (point?.isDry && point.y) {
          dataPoints = [
            ...dataPoints,
            {
              series: series.name,
              id: point.ags_data_id,
              x: point.x
            },
          ];
        }
      });
    });

    return dataPoints;
  }, [data]);

  const pointsWithComments = useMemo(() => {
    let dataPoints = [];
    data.forEach((dataSet) => {
      dataSet.data.forEach((point) => {
        if (point.comment_count) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });

    return dataPoints;
  }, [data]);

  // const makeAnnotations = useMemo(() => {
  //   const annotations = [];

  //   if (guides?.screenTop || guides?.screenTop === 0) {
  //     annotations.push({
  //       start: guides.screenTop,
  //       end: guides.screenTop + 0.02,
  //       color: config.colors.pipe,
  //       name: "Pipe screen top",
  //     });
  //   }

  //   if (guides?.screenBot || guides?.screenBot === 0) {
  //     annotations.push({
  //       start: guides.screenBot,
  //       end: guides.screenBot + 0.02,
  //       color: config.colors.pipe,
  //       name: "Pipe screen bottom",
  //     });
  //   }

  //   if (guides?.ground || guides?.ground === 0) {
  //     annotations.push({
  //       start: guides.ground - 0.0025,
  //       end: guides.ground + 0.0025,
  //       color: config.colors.groundLevel,
  //       name: "Ground level",
  //     });
  //   }
  //   return annotations;
  // }, [guides]);

  if (data && data.length && data.find(series => series.data && series.data.length)) {
    return (
      <SeriesGraph
        dataType={"waterlevels"}
        data={data}
        guides={guidesArray}
        isReversed={reversed}
        min={min}
        max={max}
        yTitle={`Groundwater Level (${units})`}
        canSelectPoint={type === "all"}
        pointsWithDryReadings={pointsWithDryReadings}
        pointsWithComments={pointsWithComments}
      ></SeriesGraph>
    );
  } else {
    return <></>;
  }
};
