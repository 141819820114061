import {
  Box,
  Typography
} from "@mui/material";
import { Suspense } from "react";
import {
  chemicalBoreholesState,
  determinantListState,
  selectedBoreholesChemistrySelector,
  selectedBoreholesChemistryState,
  selectedDeterminantSelector,
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";

import { ErrorBoundary } from "../../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../../components/esri-navigate/esri-navigate";
import { DownloadButton } from "../../components/DownloadButton";
import { PointDetails } from "../../components/pointDetails/pointDetails";
import config from "../../lib/config";
import { LoadingSpinner } from "../../components/LoadingSpinner";

import { Alerts } from './Alerts';
import { BoreholeSelect } from './BoreholeSelect';
import { DeterminantSelect } from "./DeterminantSelect";
import { CommentsBlock } from './CommentsBlock';
import { Filters } from './Filters';
import { Graph } from './Graph';
import { useAuth } from "../../components/auth-provider/auth-provider";
import { getDownloadButtonTooltip } from "../../common/getDownloadButtonTooltip";

const Chemicals = () => {
  const { user } = useAuth();
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState);
  const selectedBoreholesFromSelector = useRecoilValue(selectedBoreholesChemistrySelector);
  const determinants = useRecoilValueLoadable(
    determinantListState(selectedBoreholesChemistryState)
  );
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const [selectedDeterminant, setSelectedDeterminant] = useRecoilState(
    selectedDeterminantSelector
  );

  const downloadsDisabled = selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id).length < 1 ||
    user?.allow_downloads == false;

  let tooltipTitle = getDownloadButtonTooltip(
    downloadsDisabled,
    !!user?.allow_downloads
  );

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={4}>
        <Typography className="p-3">
          Water chemistry data. See the terms and conditions for latest upload
          date and data source details.
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <ErrorBoundary>
          <Suspense fallback=<LoadingSpinner label="Loading boreholes" />>
            {boreholes.state !== "loading" && (
              // <Card>
              //   <CardContent>
              <div className="flex">
                <BoreholeSelect className="w-full" />
                <EsriNavigateButton
                  boreholes={selectedBoreholes}
                  url={config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT}
                  componentName="ESRI_NAVIGATE_BUTTON_CHEMISTRY"
                />
                <DownloadButton
                  componentName="ESRI_NAVIGATE_BUTTON_CHEMISTRY"
                  disabled={downloadsDisabled}
                  params={{
                    locationIDs: selectedBoreholesFromSelector,
                    determinant: selectedDeterminant
                  }}
                  tooltipTitle={tooltipTitle}
                  url='/chemistry/download/csv'
                />
              </div>
              //   </CardContent>
              // </Card>
            )}
          </Suspense>
        </ErrorBoundary>
      </Box>
      <Box marginBottom={3}>
        {/* <Card>
          <CardContent> */}
        <ErrorBoundary>
          <Suspense fallback=<LoadingSpinner label="Loading determinants" />>
            {determinants.state !== "loading" && (
              <DeterminantSelect />
            )}
          </Suspense>
        </ErrorBoundary>
        {/* </CardContent>
        </Card> */}
      </Box>
      <Box marginBottom={3}>
        <ErrorBoundary>
          <Suspense>
            <Alerts></Alerts>
          </Suspense>
        </ErrorBoundary>
      </Box>
      {(selectedDeterminant && selectedBoreholes.length && (
        <>
          <Box>
            <Suspense fallback=<LoadingSpinner label="Loading data" />>
              <Filters></Filters>
              <Graph></Graph>
            </Suspense>
          </Box>
          <Box marginBottom={3}>
            <ErrorBoundary>
              <Suspense>
                <Box marginTop={3}>
                  <PointDetails dataType={"chemistry"} />
                </Box>
              </Suspense>
              <Suspense fallback=<LoadingSpinner label="Loading comments" />>
                <CommentsBlock dataType={"chemistry"} />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </>
      )) || <></>}
    </Box>
  );
};

export default Chemicals;
