import {
  Box
} from "@mui/material";
import { useTransition } from "react";
import { ThreadForm, ThreadList } from "../../components/comments/comments";
import {
  selectedDataPointFamily,
  liveWaterLevelsData,
  liveWaterLevelsDataPointThreads
} from "../../state/recoil";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil";

export const CommentsBlock = ({ dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const [start, trn] = useTransition();
  const threads = useRecoilValue(liveWaterLevelsDataPointThreads);
  const refreshThreads = useRecoilRefresher_UNSTABLE(
    liveWaterLevelsDataPointThreads
  );
  const refreshData = useRecoilRefresher_UNSTABLE(liveWaterLevelsData);

  return (
    <>
      {selectedDataPoint && (
        <>
          <Box marginBottom={3}>
            <ThreadForm
              selectedDataPoint={selectedDataPoint}
              afterSubmit={(thread) => {
                trn(() => {
                  setSelectedDataPoint({
                    ...selectedDataPoint,
                    threads: selectedDataPoint.threads ? [...selectedDataPoint.threads, thread] : [thread],
                  });
                  refreshData();
                  refreshThreads();
                });
              }}
            />
          </Box>
          <Box>
            <ThreadList threads={threads} dataType={dataType} />
          </Box>
        </>
      )}
    </>
  );
};
