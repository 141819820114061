import {
  Box
} from "@mui/material";
import { ThreadForm, ThreadList } from "../../components/comments/comments";
import {
  ccchemistryDataState as chemistryDataState,
  ccchemistryDataTypeState as chemistryDataTypeState,
  ccchemistryDatapointThreads as chemistryDatapointThreads,
  selectedDataPointFamily as selectedDataPointFamily,
} from "../../state/recoil";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
} from "recoil";

export const CommentsBlock = ({ dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const threads = useRecoilValue(chemistryDatapointThreads);
  const type = useRecoilValue(chemistryDataTypeState);
  const refreshData = useRecoilRefresher_UNSTABLE(chemistryDataState);

  return (
    <>
      {type === "all" && selectedDataPoint && (
        <>
          <Box marginBottom={3}>
            <ThreadForm
              selectedDataPoint={selectedDataPoint}
              afterSubmit={(thread) => {
                setSelectedDataPoint({
                  ...selectedDataPoint,
                  threads: selectedDataPoint.threads ? [...selectedDataPoint.threads, thread] : [thread],
                });
                refreshData();
              }}
            />
          </Box>
          <Box>
            <ThreadList threads={threads} dataType={dataType} />
          </Box>
        </>
      )}
    </>
  );
};
