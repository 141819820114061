import {
  Autocomplete,
  TextField,
  Tooltip
} from "@mui/material";
import { useEffect } from "react";
import {
  flowDataPipesState,
  selectedBoreholesFlowDataSelector
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValueLoadable
} from "recoil";

import { useSearchParams } from "react-router-dom";

export const BoreholeSelect = ({ className }) => {
  const boreholes = useRecoilValueLoadable(flowDataPipesState);
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesFlowDataSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Check for pre-selected pipes/boreholes
    const preselectedPipes = searchParams.get('pipes')?.split(',');
    const preselectedBoreholes = searchParams.get('boreholes')?.split(',');
    // const preselectedBoreholes = ['ML054-RC0001'];

    if (preselectedPipes || preselectedBoreholes) {
      const filteredPreselectedBoreholes = boreholes.getValue().filter(borehole => (
        preselectedPipes?.includes(borehole.borehole_pipe_id) ||
        preselectedBoreholes?.includes(borehole.locationdata_borehole_id)
      ));

      if (filteredPreselectedBoreholes?.length > 0) {
        setSelectedBoreholes(filteredPreselectedBoreholes);
      }
    }
  }, []);

  const handleBoreholeChange = async (event, value) => {
    if (value !== null) {
      setSelectedBoreholes(value);
    }
  };

  return <Autocomplete
    className={className}
    sx={{ userSelect: "unset" }}
    multiple={true}
    getOptionLabel={(option) => (option ? option.borehole_pipe_id : "")}
    disablePortal
    id="combo-box-demo"
    value={selectedBoreholes}
    options={boreholes.getValue()}
    renderInput={(params) => (
      <TextField {...params} label="Monitoring Point" />
    )}
    onChange={handleBoreholeChange}
  />;
};
