import {
  Box,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  chemistryDataTypeState,
  chemistryNullValuesState,
} from "../../state/recoil";
import {
  useRecoilState,
} from "recoil";

export const Filters = () => {
  const [dataType, setDataType] = useRecoilState(chemistryDataTypeState);
  const [nullValues, setNullValues] = useRecoilState(chemistryNullValuesState);

  const handleDateTypeChange = (event, value) => {
    setDataType(value.props.value);
  };

  const handleNullValuesChange = (event, value) => {
    if (value !== null) setNullValues(event.target.value);
  };

  return (
    // <Card>
    //   <CardContent>
        <Box>
          <FormGroup row>
            <FormControl sx={{ m: 1, ml: 0 }}>
              <InputLabel id={"dataType"}>Graph type</InputLabel>

              <Select
                label={"Graph type"}
                id={"dataType"}
                value={dataType}
                onChange={handleDateTypeChange}
              >
                <MenuItem value={"dailyAverage"}>Daily Average</MenuItem>
                <MenuItem value={"all"}>All readings</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"reversed"}>Null value behavior</InputLabel>
              <Select
                label={"Null value behavior"}
                id={"nullvalues"}
                value={nullValues}
                onChange={handleNullValuesChange}
              >
                <MenuItem value={"zero"}>Show as zero</MenuItem>
                <MenuItem value={"omit"}>Omit</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
    //   </CardContent>
    // </Card>
  );
};
