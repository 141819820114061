import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "../disclaimer.module.css";

import { formatDate } from "../../../common/formatDate";

export const WaterLevelsCaveats = ({ appInfo }) => {
  return (
    <Accordion className={styles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography><strong>Water Levels Page Caveats</strong></Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography component={"div"}>
          <p style={{ marginTop: 0 }}>
            Prior to use of this data visualisation tool please agree to
            the below terms of service.
          </p>
          <ol type="1">
            <li>
              <strong>General terms</strong>
              <ol type="a">
                <li>
                  All data is as provided by EKFB to GWP 
                  by {formatDate(appInfo.latest_gwl_upload_date)} ({`Load ${appInfo.latest_gwl_load_number}`}) and
                  re-produced ‘as-is’.
                </li>
                <li>
                  Any information provided after this date, by EKFB to
                  GWP (or other sources) is not included in this
                  product.
                </li>
                <li>
                  Data sources are available on request as associated
                  metadata.
                </li>
                <li>
                  Data presented is given on a factual basis only.
                </li>
                <li>
                  All data is displayed as received (with exception of
                  formatting corrections).
                </li>
                <li>Data interpretation has not been undertaken.</li>
                <li>
                  Appropriate quality assurance measures should be
                  undertaken prior to using data contain within this
                  product.
                </li>
                <li>
                  GWP Consultants LLP and Sior Consulting Ltd are not
                  liable for onward dispatch or use of this data.
                </li>
              </ol>
            </li>

            <li>
              <strong>Pipe data</strong>
              <ol type="a">
                <li>
                  Pipe references are given in the “Borehole pipe”
                  dropdown, where available pipe reference is added to
                  the borehole ID with a “#” separator, e.g. pipe
                  reference =1 where Borehole pipe = ML047=CP001#1.
                </li>
                <li>
                  For AGS datasets, pipe details are presented only
                  where:
                  <ol>
                    <li>
                      An associated AGS field "MONG_ID" (monitoring
                      point reference ID) is available; or
                    </li>
                    <li>Where "PIPE_REF" is not "NULL".</li>
                  </ol>
                </li>
                <li>
                  For non-AGS datasets, the installation or pipe
                  reference is available only where an appropriate field
                  is available in the raw dataset as received from EKFB.
                </li>
                <li>
                  Where "PIPE_REF" is ambiguous due to multiple unique
                  values per dataset and distinct grouping of "LOCA_ID"
                  (location ID) and "MONG_ID", "PIPE_REF" is presented
                  as "TBC".
                </li>
              </ol>
            </li>

            <li>
              <strong>Groundwater level data</strong>
              <ol type="a">
                <li>
                  AGS derived groundwater level data is displayed only
                  where:
                  <ol>
                    <li>
                      Data is received in the AGS "MOND" table; and
                    </li>
                    <li>
                      Where "MOND_TYPE" (Reading type) equals "WDEP".
                    </li>
                  </ol>
                </li>
                <li>
                  Data contained within "SAMP_WDEP" (Depth to the water
                  below ground surface at the time of sampling) has been
                  omitted due to a lack of supporting data to verify
                  validity.
                </li>
                <li>
                  Where no equivalent “MOND_TYPE” (Reading type) is
                  given in non-AGS datasets, “MOND_TYPE” has been
                  assumed based on “MOND_NAME” in accordance with ‘as
                  received’ datasets.
                </li>
                <li>
                  Where groundwater levels have been received with units
                  of "m", "mbgl", &amp; "GL to WL", it has been assumed
                  that the measurement relates to metres below ground
                  level (mbgl).
                </li>
                <li>
                  Location data, including groundwater, screen top,
                  screen base &amp; ground elevation data, can only be
                  assumed to be accurate to +/- 0.5m.
                </li>
                <li>
                  Location data, groundwater, screen top, screen base
                  &amp; ground elevation data is assumed to be correct
                  in the most recently issued associated dataset issued
                  by EKFB to GWP Consultants LLP (GWP), and is presented
                  on this basis.
                </li>
                <li>
                  Groundwater data is presented only where numeric
                  values for groundwater level or groundwater depth are
                  available.
                </li>
                <li>
                  Dry readings are presented only where associated remarks
                  or comments fields include 'Dry'.
                </li>
              </ol>
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};