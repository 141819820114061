import { Alert, AlertTitle, Button, CircularProgress, Grid, Typography } from "@mui/material";
// import * as Sentry from "@sentry/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import featureFlags from "../feature-flag.json";
import api from "../lib/api";
import EmailForm from "./admin/user/email-form";

export default function PasswordResetPage() {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

    const navigate = useNavigate();

    const handleFormSubmitted = async (value) => {
        setIsError(false);
        setIsLoading(true);

        try {
            await api.post(`/user/send-password-reset/${value.email}`);
            setIsSuccess(true);
        } catch (err) {
            // if (featureFlags.sentry) {
            //     Sentry.captureException(err);
            // }
            setIsError(true);
        }

        setIsLoading(false);
    }

    return (
        <Grid justifyContent="left" container>
            <Grid item xs={12}>
                <Typography variant="h2">Reset password</Typography>
            </Grid>
            <Grid className="pt-12" item xs={12} md={4}>
                {isSuccess &&
                    <Alert severity="success">
                        <AlertTitle>Password reset sent</AlertTitle>
                        You should receive password reset link shortly.
                    </Alert>
                }
                {isError &&
                    <Alert severity="error">
                        <AlertTitle>Password reset failed</AlertTitle>
                        Sending password reset link failed.
                    </Alert>
                }
                <div className="mt-4">
                    <EmailForm defaultValues={{}} onSubmit={handleFormSubmitted} />
                    <Grid justifyContent="right" container>
                        <Grid className="pt-4">
                            <Button
                                onClick={() => { navigate('/login') }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                form="email-form"
                                autoFocus
                                variant="contained"
                                disabled={isLoading || isSuccess}
                            >
                                Reset {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}
