import { colors } from "@mui/material";

const config = {
  appVersion: process.env.REACT_APP_APP_VERSION || "dev",
  clientName: process.env.REACT_APP_CLIENT_NAME || "HS2",
  environment: process.env.REACT_APP_ENVIRONMENT || "development",
  API_URL: process.env.REACT_APP_API_URL || "http://localhost:3000",
  ESRI_WEB_APP_FLOW_DATA_LAYER_URL_CONCAT: process.env.REACT_APP_ESRI_WEB_APP_FLOW_DATA_LAYER_URL_CONCAT || 'https://eiffagekier.cloud.esriuk.com/portal/apps/webappviewer/index.html?id=7f551aa903fa451eb72099f4eee9f172&showLayers=18b6c6dbca2-layer-103%3B18e09fd447f-layer-128_1&query=18b6c6dbca2-layer-103%3B18e09fd447f-layer-128_1;Location_ID%20in%20',
  ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT: process.env.REACT_APP_ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT || 'https://eiffagekier.cloud.esriuk.com/portal/apps/webappviewer/index.html?id=7f551aa903fa451eb72099f4eee9f172&showLayers=18b6c6dbbb2-layer-100%3B18e09fd447f-layer-128_1&query=18b6c6dbbb2-layer-100%3B18e09fd447f-layer-128_1;Location_ID%20in%20',
  ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT: process.env.REACT_APP_ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT || 'https://eiffagekier.cloud.esriuk.com/portal/apps/webappviewer/index.html?id=7f551aa903fa451eb72099f4eee9f172&showLayers=18b6c6dbca2-layer-103%3B18e09fd447f-layer-128_1&query=18b6c6dbca2-layer-103%3B18e09fd447f-layer-128_1;Location_ID%20in%20',
  ESRI_WEB_APP_WATER_LEVEL_LAYER_NAME: process.env.REACT_APP_ESRI_WEB_APP_WATER_LEVEL_LAYER_NAME || 'EKFB GWP GI Data AGS Hydrographs',
  ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_NAME: process.env.REACT_APP_ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_NAME || 'EKFB GWP GI Data AGS Chemographs',
  // sentryDSN: process.env.REACT_APP_SENTRY_DSN || "https://d014d4f1b87d49aaba06e86ad54025e4@glitchtip-wxi5p.ondigitalocean.app/10",
  // sentryTracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || 1.0,
  colors: {
    line: colors.blue[500],
    groundLevel: colors.grey[600],
    pipe: colors.blueGrey[400],
    gray: colors.grey[500],
    series: [
      '#6D98A6',
      '#80BC80',
      '#FFA07A',
      '#CFA1CC',
      '#E0B63C',
      '#9DA0ED',
      "#b90094",
      "#7b60d9",
      "#7080dd",
      "#659cdc",
      "#58b7d8",
      "#43d2d1",
      "#00edc9",
      "#d9df2f",
      "#e7bd3f",
      "#f09b47",
      "#f47549",
      "#f44844",
      "#e9002c",
    ],
  },
  chemistryDeterminantLabelMap: {
    pH: "pH",
    "pH : In Situ": "pH",
    "pH by Meter": "pH",
    "pH (w)": "pH",
  },
};

export default config;