import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "../disclaimer.module.css";

import { formatDate } from "../../../common/formatDate";

export const InterimWaterLevelsCaveats = ({ appInfo }) => {
  return (
    <Accordion className={styles.accordion} sx={{ mt: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography><strong>Interim Water Levels Page Caveats</strong></Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography component={"div"}>
          <p style={{ marginTop: 0 }}>
            Prior to use of this data visualisation tool please agree to
            the below terms of service.
          </p>
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Note this product uses interim data, which is yet to be issued
            as assured AGS data. All data should be appropriately quality
            assured prior to use.
          </p>
          <ol type="1">
            <li>
              <strong>General terms</strong>
              <ol type="a">
                <li>
                    All data is as provided by EKFB to GWP 
                    by {formatDate(appInfo.latest_igwl_upload_date)} via the
                    ‘groundwater sampling’ smartsheet and re-produced ‘as-is’. 
                </li>
                <li>
                    Information includes testing undertaken 
                    between {formatDate(appInfo.earliest_igwl_measurement_date)} and {formatDate(appInfo.latest_igwl_measurement_date)}.
                </li>
                <li>
                    Any information provided after this date, by EKFB to GWP
                    (or other sources) is not included in this product.
                </li>
                <li>
                    Data sources are available on request as associated metadata.
                </li>
                <li>
                    Data presented is given on a factual basis only.
                </li>
                <li>
                    All data is displayed as received (with exception of
                    formatting corrections).
                </li>
                <li>
                    Data interpretation has not been undertaken.
                </li>
                <li>
                    Appropriate quality assurance measures should be undertaken
                    prior to using data contain within this product.
                </li>
                <li>
                    GWP Consultants LLP and Sior Consulting Ltd are not liable
                    for onward dispatch or use of this data.
                </li>
              </ol>
            </li>

            <li>
              <strong>Groundwater level data</strong>
              <ol type="a">
                <li>
                    Groundwater level readings are presented only for mBGL readings from interim data sources. Conversions from datums are not calculated or presented.
                </li>
                <li>
                    Location data, including groundwater, screen top, screen base & ground elevation data, can only be assumed to be accurate to +/- 0.5m.
                </li>
                <li>
                    Location data, groundwater, screen top, screen base & ground elevation data is assumed to be correct in the most recently issued associated dataset issued by EKFB to GWP Consultants LLP (GWP), and is presented on this basis.
                </li>
                <li>
                    Groundwater data is presented only where numeric values for groundwater level, groundwater depth or dry reading details are available.
                </li>
                <li>
                    Dry readings are presented only where associated remarks or comments fields include ‘Dry’.
                </li>
              </ol>
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};