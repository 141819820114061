import {
  Autocomplete,
  TextField,
} from "@mui/material";
import { useEffect } from "react";
import {
  determinantListState,
  selectedBoreholesChemistryState,
  selectedDeterminantSelector,
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";

import { useSearchParams } from "react-router-dom";

export const DeterminantSelect = () => {
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const determinants = useRecoilValueLoadable(determinantListState(selectedBoreholes.join(",")));
  const [selectedDeterminant, setSelectedDeterminant] = useRecoilState(selectedDeterminantSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedDeterminants = searchParams.get('determinants')?.split(',');
    if (preselectedDeterminants) {
      const filteredPreselectedDeterminants = determinants.getValue().filter(determinant => preselectedDeterminants.map(d => d.toLowerCase())?.includes(determinant.toLowerCase()));
      if (filteredPreselectedDeterminants?.length > 0) {
        setSelectedDeterminant(filteredPreselectedDeterminants);
      }
    }
  }, [determinants]);

  const handleDeterminantChange = async (event, value) => {
    setSelectedDeterminant(value);
  };

  return (
    <Autocomplete
      disabled={!selectedBoreholes.length}
      getOptionLabel={(option) => (option ? option : "")}
      disablePortal
      id="combo-box-demo"
      value={selectedDeterminant}
      options={determinants.getValue()}
      renderInput={(params) => <TextField {...params} label="Determinant" />}
      onChange={handleDeterminantChange}
    />
  );
};
