import {
    Box,
    CircularProgress,
    LinearProgress
} from "@mui/material";

import './LoadingSpinner.css';

export const LoadingSpinner = props => {
    const { label } = props;
    return (
        <div className="hdaLoadingSpinner">
            <div className="hdaLoadingSpinner-label">{label}</div>
            <CircularProgress size={25} />
        </div>
    );
};