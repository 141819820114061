import {
  Box,
  Card,
  CardContent
} from "@mui/material";
import { Suspense } from "react";
import {
  selectedBoreholesLiveWaterLevelsSelector
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue
} from "recoil";

import { ErrorBoundary } from "../../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../../components/esri-navigate/esri-navigate";
import { PointDetails } from "../../components/pointDetails/pointDetails";
import config from "../../lib/config";
import { LoadingSpinner } from "../../components/LoadingSpinner";

import { Alerts } from './Alerts';
import { BoreholeSelect } from './BoreholeSelect';
import { CommentsBlock } from './CommentsBlock';
import { Graph } from './Graph';
import { DownloadButton } from "../../components/DownloadButton";
import { getDownloadButtonTooltip } from "../../common/getDownloadButtonTooltip";
import { useAuth } from "../../components/auth-provider/auth-provider";

const LiveWaterLevels = () => {
  const { user } = useAuth();
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesLiveWaterLevelsSelector);
  const pipes = useRecoilValue(selectedBoreholesLiveWaterLevelsSelector);

  const downloadsDisabled = selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id).length < 1 ||
    user?.allow_downloads == false;

  let tooltipTitle = getDownloadButtonTooltip(
    downloadsDisabled,
    !!user?.allow_downloads
  );

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={4}>
        <p className="p-3">
          Interim groundwater level data from unassured field collection forms.
          See the terms and conditions for latest upload date and data source details.
        </p>
      </Box>
      <Box marginBottom={3}>
        {/* <Card>
          <CardContent> */}
            <Suspense fallback=<LoadingSpinner label="Loading boreholes" />>
              <div className="flex">
                <BoreholeSelect className="w-full" />
                <EsriNavigateButton boreholes={selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id)} url={config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT} componentName="ESRI_NAVIGATE_BUTTON_LIVEWATERLEVELS" />
                <DownloadButton
                  componentName="DOWNLOAD_BUTTON_LIVEWATERLEVELS"
                  disabled={downloadsDisabled}
                  params={{
                    pipeIDs: pipes.map(pipe => pipe.borehole_pipe_id)
                  }}
                  tooltipTitle={tooltipTitle}
                  url='/liveWaterLevels/download/csv'
                />
              </div>
            </Suspense>
          {/* </CardContent>
        </Card> */}
      </Box>
      <Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Alerts></Alerts>
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense fallback=<LoadingSpinner label="Loading water levels" />>
              <Graph />
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <PointDetails dataType={"liveWaterLevels"} />
            </Suspense>
            <Suspense fallback=<LoadingSpinner label="Loading comments" />>
              <CommentsBlock dataType={"liveWaterLevels"} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default LiveWaterLevels;
