import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "../disclaimer.module.css";

import { formatDate } from "../../../common/formatDate";

export const WaterChemistryCaveats = ({ appInfo }) => {
  return (
    <Accordion sx={{ mt: 1 }} className={styles.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography><strong>Water Chemistry Page Caveats</strong></Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography component={"div"}>
          <p className="mt-0">
            Prior to use of this data visualisation tool please agree to
            the below terms of service.
          </p>
          <ol start="1" type="1">
            <li>
              <strong>General terms</strong>
              <ol type="a">
                <li>
                  All data is as provided by EKFB to GWP by
                  the {formatDate(appInfo.latest_chem_upload_date)} ({`Load ${appInfo.latest_chem_load_number}`}) and re-produced ‘as-is’.
                </li>
                <li>
                  Any information provided after this date, by EKFB to
                  GWP (or other sources) is not included in this
                  product.
                </li>
                <li>
                  Data sources are available on request as associated
                  metadata.
                </li>
                <li>
                  Data presented is given on a factual basis only.
                </li>
                <li>
                  All data is displayed as received (e with exception of
                  formatting corrections).
                </li>
                <li>Only numeric data has been processed.</li>
                <li>Data interpretation has not been undertaken.</li>
                <li>
                  Appropriate quality assurance measures should be
                  undertaken prior to using data contain within this
                  product.
                </li>
                <li>
                  GWP Consultants LLP and Sior Consulting Ltd are not
                  liable for onward dispatch or use of this data.
                </li>
              </ol>
            </li>
            <li>
              <strong>Chemistry data</strong>
              <ol start="0" type="a">
                <li style={{ listStyle: "none" }}>
                  Chemistry data is presented from AGS tables “ERES”
                  (Environmental Contaminant Testing ) and “GCHM”
                  (Geotechnical Chemistry Testing) where, denoted as
                  "EW", "Water - EW", "Water - SW", "WATER" or "RIVER /
                  Running Surface Water" within the AGS field
                  "SAMP_TYPE" (sample type).
                </li>
                <li>
                  Chemistry data is presented from AGS table "MOND"
                  (Field-based measurements) where:
                  <ol type="i">
                    <li>
                      AGS field "MOND_TYPE" is not equal to; "WDEP",
                      "WLEV", "DBLS", "BLEV", "PRES", "BAR", "FLOW",
                      "GPRS", "WHD" or "TEMP"; or
                    </li>
                    <li>
                      AGS field "MOND_NAME" (Client preferred name of
                      measurement) is not "NULL".
                    </li>
                  </ol>
                </li>

                <li>
                  Where no equivalent "MOND_TYPE" is given in non-AGS
                  datasets, "MOND_TYPE" has been assumed based on
                  "MOND_NAME" in accordance with ‘as received’ datasets.
                </li>
              </ol>
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};