import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "../disclaimer.module.css";

import { formatDate } from "../../../common/formatDate";

export const FlowDataCaveats = ({ appInfo }) => {
  return (
    <Accordion className={styles.accordion} sx={{ mt: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography><strong>Flow Data Page Caveats</strong></Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography component={"div"}>
          <p className="mt-0">
            Prior to use of this data visualisation tool please agree to
            the below terms of service.
          </p>
          <ol type="1">
            <li>
              <strong>General terms</strong>
              <ol type="a">
                <li>
                  All data is as provided by EKFB to GWP
                  by {formatDate(appInfo.latest_gwl_upload_date)} ({`Load ${appInfo.latest_gwl_load_number}`}) and
                  re-produced ‘as-is’.
                </li>
                <li>
                  Any information provided after this date, by EKFB to GWP
                  (or other sources) is not included in this product.
                </li>
                <li>
                  Data sources are available on request as associated metadata.
                </li>
                <li>
                  Data presented is given on a factual basis only.
                </li>
                <li>
                  All data is displayed as received (with exception of
                  formatting corrections).
                </li>
                <li>
                  Data interpretation has not been undertaken.
                </li>
                <li>
                  Appropriate quality assurance measures should be undertaken
                  prior to using data contain within this product.
                </li>
                <li>
                  GWP Consultants LLP and Sior Consulting Ltd are not liable
                  for onward dispatch or use of this data.
                </li>
              </ol>
            </li>

            <li>
              <strong>Flow data</strong>
              <ol type="a">
                <li>
                  AGS derived flow data is displayed only where:
                  <ol type="i">
                    <li>
                      Data is received in the AGS "MOND" table; and
                    </li>
                    <li>
                      Where "MOND_TYPE" (Reading type) equals " FLOW".
                    </li>
                  </ol>
                </li>
                <li>
                  Where no equivalent “MOND_TYPE” (Reading type) is given in non-AGS datasets, “MOND_TYPE” has been assumed based on “MOND_NAME” in accordance with ‘as received’ datasets.
                </li>
                <li>
                  Flow data is presented only where numeric values are available.
                </li>
                <li>
                  Location data, can only be assumed to be accurate to +/- 0.5m.
                </li>
              </ol>
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};