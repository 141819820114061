import {
  Box
} from "@mui/material";
import { useTransition } from "react";
import { ThreadForm, ThreadList } from "../../components/comments/comments";
import {
  selectedDataPointFamily,
  waterLevelsData,
  waterLevelsDataPointThreads,
  waterLevelsDataType
} from "../../state/recoil";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue
} from "recoil";

export const CommentsBlock = ({ dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const [, startTransition] = useTransition();
  const threads = useRecoilValue(waterLevelsDataPointThreads);
  const type = useRecoilValue(waterLevelsDataType);
  const refreshThreads = useRecoilRefresher_UNSTABLE(
    waterLevelsDataPointThreads
  );
  const refreshData = useRecoilRefresher_UNSTABLE(waterLevelsData);

  return (
    <>
      {type === "all" && selectedDataPoint && (
        <>
          <Box marginBottom={3}>
            <ThreadForm
              selectedDataPoint={selectedDataPoint}
              afterSubmit={(thread) => {
                startTransition(() => {
                  setSelectedDataPoint({
                    ...selectedDataPoint,
                    threads: selectedDataPoint.threads ? [...selectedDataPoint.threads, thread] : [thread],
                  });
                  refreshData();
                  refreshThreads();
                });
              }}
            />
          </Box>
          <Box>
            <ThreadList threads={threads} dataType={dataType} />
          </Box>
        </>
      )}
    </>
  );
};
