import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import styles from "../disclaimer.module.css";

import { formatDate } from "../../../common/formatDate";

export const ChemconnectCaveats = ({ appInfo }) => {
  return (
    <Accordion className={styles.accordion} sx={{ mt: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography><strong>Chemconnect Page Caveats</strong></Typography>
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        <Typography component={"div"}>
          <p className="mt-0">
            Prior to use of this data visualisation tool please agree to
            the below terms of service.
          </p>
          <p style={{ color: 'red', fontWeight: 'bold' }}>
            Note this product uses interim data, which is yet to be issued
            as assured AGS data. All data should be appropriately quality
            assured prior to use.
          </p>
          <ol start="1" type="1">
            <li>
              <strong>General terms</strong>
              <ol type="a">
                <li>
                  All data is as provided by EKFB to GWP
                  by {formatDate(appInfo.latest_cconn_upload_date)} via 
                  the Chemconnect portal and re-produced ‘as-is’. 
                </li>
                <li>
                  Information includes testing undertaken
                  between {formatDate(appInfo.earliest_cconn_measurement_date)} and {formatDate(appInfo.latest_cconn_measurement_date)}.  
                </li>
                <li>
                  Any information provided after this date, by EKFB to
                  GWP (or other sources) is not included in this
                  product.
                </li>
                <li>
                  Data sources are available on request as associated
                  metadata.
                </li>
                <li>
                  Data presented is given on a factual basis only.
                </li>
                <li>
                  All data is displayed as received (with exception of
                  formatting corrections).
                </li>
                <li>Data interpretation has not been undertaken.</li>
                <li>
                  Appropriate quality assurance measures should be
                  undertaken prior to using data contain within this
                  product.
                </li>
                <li>
                  GWP Consultants LLP and Sior Consulting Ltd are not
                  liable for onward dispatch or use of this data.
                </li>
              </ol>
            </li>
            <li>
              <strong>Chemconnect data</strong>
              <ol type="a">
                <li>
                  Chemistry data is presented from AGS “ERES”
                  (Environmental Contaminant Testing) table.
                </li>
              </ol>
            </li>
          </ol>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};