export const getDownloadButtonTooltip = (disabled, allowDownloads) => {
    let tooltipTitle;

    switch (true) {
        case !disabled:
            tooltipTitle = 'Download data as CSV file';
            break;

        case !allowDownloads:
            tooltipTitle = 'Downloads currently unavailable';
            break;

        default:
            tooltipTitle = 'Select data to download as CSV file';
    }

    return tooltipTitle;
};
