import { atom, selector } from 'recoil';

import api from '../lib/api';
import featureFlags from '../feature-flag.json';
import logUserActivity from '../common/user-activity-logging';

// Main recoil imports
import {
  commentsState,
  dateWindowState,
  selectedDataPointFamily,
  valueRangeState,
  visibleSeriesState
} from './recoil';


// =====
// Live Water Levels
export const liveWaterLevelsPipesState = selector({
  key: 'liveWaterLevelPipes',
  get: async ({ get }) => {
    const response = await api.get('liveWaterLevels/pipes');
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const liveWaterLevelsBoreholesState = selector({
  key: 'liveWaterLevelsBoreholes',
  get: async ({ get }) => {
    const response = await api.get('liveWaterLevels/boreholes');
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const selectedBoreholesLiveWaterLevelsState = atom({
  key: 'selectedBoreholesLiveWaterLevels',
  default: [],
});

export const selectedBoreholesLiveWaterLevelsSelector = selector({
  key: 'selectedBoreholesLiveWaterLevelsSelector',
  get: ({ get }) => {
    return get(selectedBoreholesLiveWaterLevelsState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily('liveWaterLevels'), null);

    const visibleSeries = newValue.map((borehole) => ({
      name: borehole.borehole_pipe_id,
      visible: true,
    }));

    // // add extra series for the rainfall data
    // if (newValue.length === 1) {
    //   visibleSeries.push({ name: 'Rainfall', visible: true });
    // }

    set(visibleSeriesState('liveWaterLevels'), visibleSeries);

    set(selectedBoreholesLiveWaterLevelsState, newValue);

    logUserActivity({
      action: 'BOREHOLE_SELECTED',
      component: 'liveWaterLevels',
      data: newValue,
    });
  },
});

// export const liveWaterLevelsDataType = atom({
//   key: 'liveWaterLevelsDataType',
//   default: 'all',
// });

// export const liveWaterLevelsDataTypeSelector = selector({
//   key: 'liveWaterLevelsDataTypeSelector',
//   get: ({ get }) => {
//     return get(liveWaterLevelsDataType);
//   },

//   set: ({ set }, newValue) => {
//     if (newValue === 'all') {
//       set(selectedDataPointFamily('liveWaterLevels'), null);
//     }
//     set(liveWaterLevelsDataType, newValue);
//   },
// });

export const liveWaterLevelsUnits = atom({
  key: 'liveWaterLevelsUnits',
  default: "mBGL",
});

// export const liveWaterLevelsUnitsSelector = selector({
//   key: 'liveWaterLevelsUnitsSelector',
//   get: ({ get }) => {
//     return get(liveWaterLevelsUnits);
//   },

//   set: ({ set }, newValue) => {
//     if (newValue === 'mbgl') {
//       set(liveWaterLevelsGraphReversed, true);
//     }
//     set(liveWaterLevelsUnits, newValue);
//   },
// });

// export const liveWaterLevelsGraphReversed = atom({
//   key: 'waterLevelsGraphReversed',
//   default: false,
// });

export const liveWaterLevelsLocationData = selector({
  key: 'liveWaterLevelsLocationData',
  get: async ({ get }) => {
    const boreholes = get(selectedBoreholesLiveWaterLevelsSelector);
    if (!boreholes) {
      return [];
    }
    const promises = Promise.all(
      boreholes.map((borehole) => api.get(`location/${borehole.borehole_pipe_id}`))
    );
    const responses = await promises;
    return responses.map((response) => response.data);
  },
});

export const allLiveWaterLevelsLocationsHaveNationalGridElevation = selector({
  key: 'allLiveWaterLevelsLocationsHaveNationalGridElevation',
  get: ({ get }) => {
    const locationData = get(liveWaterLevelsLocationData);
    let res = !locationData.find(
      (item) => item.national_grid_elevation === null
    );
    return res;
  },
});

export const liveWaterLevelsData = selector({
  key: 'liveWaterLevelsData',
  get: async ({ get }) => {
    const selectedBoreholes = get(selectedBoreholesLiveWaterLevelsSelector);

    if (!selectedBoreholes) {
      return [];
    }

    const promises = Promise.all(
      selectedBoreholes.map((borehole) =>
        api.get(
          `liveWaterLevels/series/${encodeURIComponent(
            borehole.borehole_pipe_id
          )}`,
          { params: { pipeType: borehole.pipe_type } }
        )
      )
    );
    const responses = await promises;

    return responses.map((item) => item.data);
  },
});

export const liveWaterLevelsSeries = selector({
  key: 'liveWaterLevelsSeries',
  get: ({ get }) => {
    const data = get(liveWaterLevelsData);
    const selectedBoreholes = get(selectedBoreholesLiveWaterLevelsSelector);

    if (!data) {
      return null;
    }

    // Process water level data
    let seriesData = data.map((item, index) => {
      const modifiedData = [];
      const boreholePipeBase = item.guides.screenBot;

      item.series.forEach((point) => {
        modifiedData.push({
          ...point,
          y: point.isDry ? (boreholePipeBase !== undefined ? boreholePipeBase : NaN) : point.y
        });
      });

      return {
        name: selectedBoreholes[index].borehole_pipe_id,
        data: modifiedData,
        index,
      };
    });

    // // Include rainfall data if the feature flag is enabled
    // if (featureFlags.rainfall) {
    //   const rainfallData = get(nearestRainfallStationData);
    //   if (selectedBoreholes.length === 1 && rainfallData?.length) {
    //     seriesData = [
    //       ...seriesData,
    //       {
    //         data: rainfallData.map((item) => ({
    //           y: item.value,
    //           x: item.date,
    //         })),
    //         name: 'Rainfall',
    //         index: 1,
    //       },
    //     ];
    //   }
    // }

    return seriesData;
  },
});

// export const nearestRainfallStation = selector({
//   key: 'liveWaterLevelsNearestStation',
//   get: async ({ get }) => {
//     const selected = get(selectedBoreholesLiveWaterLevelsSelector);
//     if (selected.length !== 1) {
//       return null;
//     }
//     try {
//       const { data: stations } = await api.get(
//         "/rainfall/nearby_stations/" + selected[0].borehole_pipe_id.split('#')[0]
//       );
//       return stations[0];
//     } catch (e) {
//       return null;
//     }
//   },
// });

// export const nearestRainfallStationData = selector({
//   key: 'liveWaterLevelsNearestStationRainfall',
//   get: async ({ get }) => {
//     const nearestStation = get(nearestRainfallStation);
//     const location = get(liveWaterLevelsLocationData);
//     if (!nearestStation || !location) {
//       return null;
//     }

//     const { data: readings } = await api.get(
//       "/rainfall/station/" +
//       nearestStation.stationReference +
//       "/readings/dailyTotal"
//     );
//     return readings;
//   },
// });

export const liveWaterLevelsGuides = selector({
  key: 'liveWaterLevelsGuides',
  get: ({ get }) => {
    const data = get(liveWaterLevelsData);
    if (data.length === 1) {
      return data[0].guides;
    }
    return null;
  },
});

// Get all dry readings grouped by pipe name/series name for pipes with no base
export const liveWaterLevelsNoBaseDrys = selector({
  key: 'liveWaterLevelsNoBaseDrys',
  get: ({ get }) => {
    const seriesData = get(liveWaterLevelsSeries);
    const data = get(liveWaterLevelsData);

    let noBaseDryReadings = null;
    data.forEach((d, i) => {
      // If series has no drys, ignore
      if (d?.meta.hasDryReadings === false) {
        return;
      }

      // If series has dry readings but no pipe base info, list in warning card
      if (!d?.guides?.screenBot) {
        if (noBaseDryReadings === null) {
          noBaseDryReadings = {};
        }

        const seriesName = seriesData.find(sd => sd.index === i).name;
        noBaseDryReadings[seriesName] = [];

        // Loop each item in series
        let additionalDryCount = 0;
        const dLength = d.series.length;
        for (let i = 0; i < dLength; i++) {
          // If reading isn't dry, skip
          if (!d.series[i].isDry) {
            continue;
          }

          // Add to list for warning card if less than 10 already
          if (noBaseDryReadings[seriesName].length < 10) {
            noBaseDryReadings[seriesName].push({
              id: d.series[i].id,
              dateTime: d.series[i].x
            });
          }
          else {
            additionalDryCount ++;
          }
        }

        // If there are over 10 additional readings to warn about, give count
        if (additionalDryCount > 0) {
          noBaseDryReadings[seriesName].push({
            more: additionalDryCount
          });
        }
      }
    });

    return noBaseDryReadings;
  },
});

export const liveWaterLevelsMin = selector({
  key: 'liveWaterLevelsMin',
  get: ({ get }) => {
    const guides = get(liveWaterLevelsGuides);
    if (!guides) {
      return null;
    }
    return Math.min(
      ...[
        guides?.highest,
        guides?.screenBot,
        guides?.lowest,
        guides?.screenTop,
        guides?.groundLevel,
      ].filter(Number.isFinite)
    );
  },
});

export const liveWaterLevelsMax = selector({
  key: 'liveWaterLevelsMax',
  get: ({ get }) => {
    const guides = get(liveWaterLevelsGuides);
    if (!guides) {
      return undefined;
    }

    // TODO: check this dynamically by looping
    return Math.max(
      ...[
        guides?.highest,
        guides?.screenBot,
        guides?.lowest,
        guides?.screenTop,
        guides?.groundLevel,
      ].filter(Number.isFinite)
    );
  },
});

// // TODO: maybe find a way to not hardcode this logic for every graph.
// // it works for now but might become annoying later.
// export const selectedLiveWaterLevelsDataPointMeta = selector({
//   key: 'selectedDataPointMeta',
//   get: ({ get }) => {
//     const dataPoint = get(selectedDataPointFamily('liveWaterLevels'));
//     const data = get(liveWaterLevelsData);
//     const selectedBoreholes = get(selectedBoreholesLiveWaterLevelsSelector);

//     if (!dataPoint || !selectedBoreholes.length) {
//       return null;
//     }

//     return {
//       id: data[dataPoint.seriesIndex].series[dataPoint.dataPointIndex].id,
//       boreholeId: selectedBoreholes[dataPoint.seriesIndex].borehole_pipe_id.split("#")[0],
//       boreholePipeId: selectedBoreholes[dataPoint.seriesIndex].borehole_pipe_id,
//     };
//   },
// });

export const liveWaterLevelsDataPointThreads = selector({
  key: 'liveWaterLevelsThreads',
  get: ({ get }) => {
    const datapoint = get(selectedDataPointFamily('liveWaterLevels'));
    if (!datapoint || !datapoint.threads) {
      return [];
    }
    return datapoint.threads;
  },
});
