import { Navigate, useLocation } from "react-router";

import { useAuth } from "../auth-provider/auth-provider";

const ProtectedRoute = ({ children, roles = [] }) => {
  const location = useLocation();
  const { isAuthenticated, user } = useAuth();

  const protectedGroupRoutes = [
    { path: '/waterLevels', isAllowed: user?.allow_waterlevel ? true : false },
    { path: '/flowData', isAllowed: user?.allow_flowdata ? true : false },
    { path: '/waterChemistry', isAllowed: user?.allow_chemistry ? true : false }
  ];

  const allowedRoutes = ['/faq', '/waterLevels', '/flowData', '/waterChemistry'];

  const protectedGroupRoute = protectedGroupRoutes.find(route => location.pathname.indexOf(route.path) === 0);
  const isGroupAllowed = protectedGroupRoute ? protectedGroupRoute.isAllowed : true;
  const isRoleAllowed = roles.length > 0 ? roles.some(role => user?.role === role) : true;

  const allow = isAuthenticated && isRoleAllowed && isGroupAllowed;

  if (allow) {
    return children;
  } else if (!isRoleAllowed && isAuthenticated) {
    return <Navigate to="/dashboard" replace />;
  } else {
    if (allowedRoutes.includes(location.pathname)) {
      sessionStorage.setItem('preAuthURL', location.pathname + location.search);
    }
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
