import { atom, selector } from "recoil";

import api from "../lib/api";
import logUserActivity from "../common/user-activity-logging";

export const frontEndSettingsState = selector({
  key: 'frontEndSettings',
  get: async ({ get }) => {
    const response = await api.get('front-end-settings');
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});
