import './esri-navigate.css';

import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from "@mui/material";
import React, { useState } from 'react';

import api from "../../lib/api";
import logUserActivity from "../../common/user-activity-logging";

const EsriNavigateButton = ({ boreholes, url, className = "", tooltipTitle = "Open selected monitoring point(s) in geospatial GWR App", componentName }) => {
    const [open, setOpen] = useState(false);
    const [missingLocations, setMissingLocations] = useState([]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleContinueNavigation = () => {
        handleClose();
        handleNavigate();
    };

    const handleCheckCoordinates = async () => {
        try {
            const response = await api.post('/location-standardised/check-coordinates', { boreholes });
            const locationsWithMissingCoordinates = response.data.filter(loc => !loc.hasCoordinates);
            if (locationsWithMissingCoordinates.length > 0) {
                setMissingLocations(locationsWithMissingCoordinates);
                setOpen(true);
            } else {
                handleNavigate();
            }
        } catch (error) {
            console.error('Error checking coordinates:', error);
        }
    };

    const handleNavigate = () => {
        if (url && boreholes.length) {
            let QUERY = boreholes?.map(borehole => `'${borehole}'`).join(',');
            QUERY = `(${QUERY})`;
            const _url = `${url}${QUERY}`;
            window.open(_url, '_blank');

            logUserActivity({
                action: 'CLICKED',
                component: componentName,
                data: _url,
            });
        }
    };

    const idsText = missingLocations.map(loc => loc.id).join(', ');
    const doesDoText = missingLocations.length === 1 ? 'does' : 'do';

    return (
        <div className="ml-3">
            <Tooltip title={tooltipTitle}>
                <Button disabled={boreholes.length < 1} className={`hda-esri-navigate ${className}`} variant="outlined" onClick={handleCheckCoordinates}>
                    <Avatar src="assets/img/icon-esri-word.png" alt="esri" />
                </Button>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Warning: no available coordinates</DialogTitle>

                <DialogContent dividers>
                    <DialogContentText color="main">
                        <b>{idsText}</b> {doesDoText} not have coordinates available, and cannot be georeferenced in the geospatial Groundwater Risk App (GWR App). It may be that this borehole has other aliases with coordinates available. Do you want to continue to the geospatial GWR App?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleContinueNavigation} color="primary" autoFocus variant="contained">Continue</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default EsriNavigateButton;


