// import * as Sentry from "@sentry/react";

import { Alert, AlertTitle, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRolesOptions } from "../lib/user-roles";

import UserForm from "./admin/user/user-form";
import api from "../lib/api";
import featureFlags from "../feature-flag.json";
import { useParams } from "react-router";

export default function RegisterPage() {
    const { id } = useParams();
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [defaultValues, setDefaultValues] = useState({});
    

    const navigate = useNavigate();

    useEffect(() => {
        const getInvite = async () => {
            try {
                const invite = (await api.get(`/user/invite/${id}`)).data;
                if (invite && invite.id && !invite.is_complete) {
                    setDefaultValues({email: invite.email, role: userRolesOptions.find(r => r.value === invite.role) })
                    setIsReady(true);
                } else {
                    setIsExpired(true);
                }
            } catch (err) {
                // if (featureFlags.sentry) {
                //     Sentry.captureException(err);
                // }
                setIsExpired(true);
            }
        }

        getInvite().catch(console.log);
    }, [id])


    const handleFormSubmitted = async (value) => {
        setIsError(false);
        setIsExpired(false);
        setIsSuccess(false);
        setIsLoading(true);

        try {
            const user = await api.post(`/user/register/${id}`, value);
            if (user.data && user.data.id) {
                setIsSuccess(true);
            } else {
                setIsError(true);
            }
        } catch (err) {
            // if (featureFlags.sentry) {
            //     Sentry.captureException(err);
            // }
            setIsError(true);
        }

        setIsLoading(false);
    }

    return (
        <Grid justifyContent="left" container>
            <Grid item xs={12}>
                <Typography variant="h2">Register</Typography>
            </Grid>
            <Grid className="pt-12" item xs={12} md={4}>
                {isSuccess &&
                    <Alert severity="success">
                        <AlertTitle>User registered</AlertTitle>
                        You have successfully registered.
                        <br />
                        You can now <Link to="/login"><strong>Login</strong></Link>.
                    </Alert>
                }
                {isError &&
                    <Alert severity="error">
                        <AlertTitle>Registration failed</AlertTitle>
                        User registration failed.
                    </Alert>
                }
                {isExpired &&
                    <Alert severity="error">
                        <AlertTitle>Link expired</AlertTitle>
                        The link has expired after certain amount of time.
                    </Alert>
                }
                {isReady &&
                    <div className="mt-4">
                        <UserForm isEditMode={false} defaultValues={defaultValues} onSubmit={handleFormSubmitted} isEmailDisabled isRoleDisabled isGroupsHidden />
                        <Grid justifyContent="right" container>
                            <Grid className="pt-4">
                                <Button
                                    onClick={() => { navigate('/login') }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    form="user-form"
                                    autoFocus
                                    variant="contained"
                                    disabled={isLoading || isSuccess}
                                >
                                    Register {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                }
            </Grid>
        </Grid>
    );
}
