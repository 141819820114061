import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { Suspense } from "react";
import {
  selectedBoreholesFlowDataSelector
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue
} from "recoil";

import { ErrorBoundary } from "../../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../../components/esri-navigate/esri-navigate";
import { Feature } from "flagged";
import { PointDetails } from "../../components/pointDetails/pointDetails";
import config from "../../lib/config";
import { DownloadButton } from "../../components/DownloadButton";

import { LoadingSpinner } from '../../components/LoadingSpinner';

// Flow Data component imports
import { Alerts } from "./Alerts";
import { BoreholeSelect } from './BoreholeSelect';
import { CommentsBlock } from "./CommentsBlock";
import { Graph } from './Graph';
import { RainfallStationData } from './RainfallStationData';
import { useAuth } from "../../components/auth-provider/auth-provider";
import { getDownloadButtonTooltip } from "../../common/getDownloadButtonTooltip";

// Flow
const FlowData = () => {
  const { user } = useAuth();
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesFlowDataSelector);
  const pipes = useRecoilValue(selectedBoreholesFlowDataSelector);

  const downloadsDisabled = selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id).length < 1 ||
    user?.allow_downloads == false;

  let tooltipTitle = getDownloadButtonTooltip(
    downloadsDisabled,
    !!user?.allow_downloads
  );

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={4}>
        <Typography className="p-3">
          Flow data measurements. See the terms and conditions for latest upload date and data source details.
        </Typography>
      </Box>
      <Box marginBottom={3}>
        {/* <Card>
          <CardContent> */}
            <Suspense fallback=<LoadingSpinner label="Loading boreholes" />>
              <div className="flex">
                <BoreholeSelect className="w-full" />
                <EsriNavigateButton
                  boreholes={selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id)}
                  componentName="ESRI_NAVIGATE_BUTTON_FLOWDATA"
                  url={config.ESRI_WEB_APP_FLOW_DATA_LAYER_URL_CONCAT}
                />
                <DownloadButton
                  componentName="ESRI_NAVIGATE_BUTTON_FLOWDATA"
                  disabled={downloadsDisabled}
                  params={{
                    pipeIDs: pipes.map(pipe => pipe.borehole_pipe_id)
                  }}
                  tooltipTitle={tooltipTitle}
                  url='/flowdata/download/csv'
                />
              </div>
            </Suspense>
          {/* </CardContent>
        </Card> */}
      </Box>
      <Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Alerts />
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Feature name="rainfall">
          <Box marginBottom={3}>
            <ErrorBoundary>
              <Suspense fallback=<LoadingSpinner label="Loading rainfall station info" />>
                <RainfallStationData />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Feature>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense fallback=<LoadingSpinner label="Loading flow data" />>
              <Graph />
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <PointDetails dataType={'flowData'} />
            </Suspense>
            <Suspense fallback=<LoadingSpinner label="Loading comments" />>
              <CommentsBlock dataType={'flowData'} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default FlowData;

