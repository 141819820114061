import {
  Autocomplete,
  TextField,
  Tooltip
} from "@mui/material";
import { useEffect } from "react";
import {
  selectedBoreholesLiveWaterLevelsSelector,
  liveWaterLevelsPipesState
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValueLoadable
} from "recoil";

import { useSearchParams } from "react-router-dom";

export const BoreholeSelect = ({ className }) => {
  const boreholes = useRecoilValueLoadable(liveWaterLevelsPipesState);
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesLiveWaterLevelsSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedPipes = searchParams.get('pipes')?.split(',');
    // FOR UI TESTING:
    // const preselectedPipes = [];

    const preselectedBoreholes = searchParams.get('boreholes')?.split(',');
    if (preselectedPipes || preselectedBoreholes) {
      const filteredPreselectedBoreholes = boreholes.getValue().filter(
        borehole => (
          preselectedPipes?.includes(borehole.borehole_pipe_id) ||
          preselectedBoreholes?.includes(borehole.locationdata_borehole_id)
        )
      );
      if (filteredPreselectedBoreholes?.length > 0) {
        setSelectedBoreholes(filteredPreselectedBoreholes);
      }
    }
  }, []);

  const handleBoreholeChange = async (event, value) => {
    if (value !== null) {
      setSelectedBoreholes(value);
    }
  };

  return (
    <Tooltip
      title={"Pipe screen data will not be displayed for multiple boreholes"}
    >
      <Autocomplete
        className={className}
        sx={{ userSelect: "unset" }}
        multiple={true}
        getOptionLabel={(option) => (option ? option.borehole_pipe_id : "")}
        disablePortal
        id="combo-box-demo"
        value={selectedBoreholes}
        options={boreholes.getValue()}
        renderInput={(params) => (
          <TextField {...params} label="Borehole pipe" />
        )}
        onChange={handleBoreholeChange}
      />
    </Tooltip>
  );
};
