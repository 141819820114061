import {
  Box,
  Card,
  CardContent,
  Typography
} from "@mui/material";
import { Suspense } from "react";
import {
  selectedBoreholesWaterlevelsSelector
} from "../../state/recoil";
import {
  useRecoilState,
  useRecoilValue
} from "recoil";

import { ErrorBoundary } from "../../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../../components/esri-navigate/esri-navigate";
import { DownloadButton } from "../../components/DownloadButton";
import { Feature } from "flagged";
import { PointDetails } from "../../components/pointDetails/pointDetails";
import config from "../../lib/config";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { useAuth } from "../../components/auth-provider/auth-provider";

import { Alerts } from './Alerts';
import { BoreholeSelect } from './BoreholeSelect';
import { CommentsBlock } from './CommentsBlock';
import { Filters } from './Filters';
import { Graph } from './Graph';
import { RainfallStationData } from './RainfallStationData';

import { getDownloadButtonTooltip } from "../../common/getDownloadButtonTooltip";

const WaterLevels = () => {
  const { user } = useAuth();
  const [selectedBoreholes,] = useRecoilState(selectedBoreholesWaterlevelsSelector);
  const pipes = useRecoilValue(selectedBoreholesWaterlevelsSelector);

  const downloadsDisabled = selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id).length < 1 ||
    user?.allow_downloads == false;

  let tooltipTitle = getDownloadButtonTooltip(
    downloadsDisabled,
    !!user?.allow_downloads
  );

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={4}>
        <Typography className="p-3">
          Groundwater level data. See the terms and conditions for latest
          upload date and data source details.
        </Typography>
      </Box>
      <Box marginBottom={3}>
        {/* <Card>
          <CardContent> */}
        <Suspense fallback=<LoadingSpinner label="Loading boreholes" />>
          <div className="flex">
            <BoreholeSelect className="w-full" />
            <EsriNavigateButton boreholes={selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id)} url={config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT} componentName="ESRI_NAVIGATE_BUTTON_WATERLEVEL" />
            <DownloadButton
              componentName="DOWNLOAD_BUTTON_WATER_LEVELS"
              disabled={downloadsDisabled}
              params={{
                pipeIDs: pipes.map(pipe => pipe.borehole_pipe_id)
              }}
              tooltipTitle={tooltipTitle}
              url='/waterlevel/download/csv'
            />
          </div>
        </Suspense>
        {/* </CardContent>
        </Card> */}
      </Box>
      <Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Filters></Filters>
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Alerts></Alerts>
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Feature name="rainfall">
          <Box marginBottom={3}>
            <ErrorBoundary>
              <Suspense fallback=<LoadingSpinner label="Loading rainfall station info" />>
                <RainfallStationData />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Feature>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense fallback=<LoadingSpinner label="Loading water levels" />>
              <Graph />
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <PointDetails dataType={"waterlevels"} />
            </Suspense>
            <Suspense fallback=<LoadingSpinner label="Loading comments" />>
              <CommentsBlock dataType={"waterlevels"} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default WaterLevels;
