import {
  Box,
  Card,
  CardContent
} from "@mui/material";
import {
  liveWaterLevelsNoBaseDrys,
  liveWaterLevelsPipesState,
  liveWaterLevelsSeries
} from "../../state/recoil";
import {
  useRecoilValue,
  useRecoilValueLoadable
} from "recoil";

import { Alert } from "../../components/alert/alert";
import config from "../../lib/config";
import logUserActivity from "../../common/user-activity-logging";
import { useSearchParams } from "react-router-dom";

export const Alerts = () => {
  const data = useRecoilValue(liveWaterLevelsSeries);
  const boreholes = useRecoilValueLoadable(liveWaterLevelsPipesState).getValue() || [];

  // Check for 'dry' readings
  const hasDryReadings = data.some(series => series.data.some(point => point?.isDry));
  const noBaseDryReadings = useRecoilValue(liveWaterLevelsNoBaseDrys);

  const checkSeriesForData = series => series.name.toUpperCase().includes('RAINFALL') || series.data.some(point => !isNaN(point.y));
  const locationsWithoutUnitData = data.filter(series => !checkSeriesForData(series)).map(series => series.name);

  const [searchParams] = useSearchParams();
  const preselectedBoreholes = searchParams.get('boreholes')?.split(',') || [];
  const preselectedPipes = searchParams.get('pipes')?.split(',') || [];

  const preselectedBoreholesWithoutData = preselectedBoreholes.filter(preselectedBorehole => !boreholes.find(borehole => preselectedBorehole === borehole.locationdata_borehole_id));
  const preselectedPipesWithoutData = preselectedPipes.filter(preselectedPipe => !boreholes.find(borehole => preselectedPipe === borehole.borehole_pipe_id));

  const hasAlerts = locationsWithoutUnitData.length > 0 || preselectedBoreholesWithoutData.length > 0 || preselectedPipesWithoutData.length > 0 || hasDryReadings;

  const handleAGOLClick = () => {
    logUserActivity({
      action: 'CLICKED',
      component: 'ALERT_AGOL_LINK_WATERLEVEL',
      data: config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT + preselectedBoreholesWithoutData.join(','),
    });
  };

  return hasAlerts && (
    <Box>
      {/* <Card>
        <CardContent> */}
          {locationsWithoutUnitData.length > 0 && <Alert title={`No graphable information for available monitoring ${locationsWithoutUnitData.length > 1 ? 'points' : 'point'}`}>
            <p>No graphable groundwater level data is available for <strong>{locationsWithoutUnitData.join(', ')}</strong>.</p>
            <p className="mb-1">It may be that no monitoring data is available for this point, or that 'dry' readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).</p>
          </Alert>}
          {preselectedBoreholesWithoutData.length > 0 && <Alert title={`No information for available monitoring ${preselectedBoreholesWithoutData.length > 1 ? 'points' : 'point'}`}>
            No graphable groundwater level data is available for <strong>{preselectedBoreholesWithoutData.join(', ')}</strong>. <br />
            It may be that no monitoring data is available for this point, or that 'dry' readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
          </Alert>}
          {preselectedPipesWithoutData.length > 0 && <Alert title={`No information for available monitoring ${preselectedPipesWithoutData.length > 1 ? 'installations' : 'installation'}`}>
            No graphable groundwater level data is available for <strong>{preselectedPipesWithoutData.join(', ')}</strong>. <br />
            It may be that no monitoring data is available for this point, or that 'dry' readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
          </Alert>}
          {hasDryReadings && <Alert severity="warning" title="Dry readings detected">
            ‘Dry’ readings detected within the raw data. Some, or all, of the ‘dry’ readings cannot be accurately plotted on the below graph; please refer to raw data for more details (download button below), or hydrograph excel export(s) accessed via the geospatial GWR App (link above).
            {noBaseDryReadings !== null && <>
              <p><strong>
                One or more of the selected pipes does not have pipe screen base information,
                so we are unable to plot those dry readings. These readings include:
              </strong></p>
              <ul>
                {Object.keys(noBaseDryReadings).map(pipeName =>
                  <li key={`nbdr_pn_${pipeName}`}>
                    <strong>{pipeName}</strong>
                    <ul>
                      {noBaseDryReadings[pipeName].map((i, index) => {
                        if (index < 10) {
                          return (
                            <li key={`nbdr_r_${i.id}`}>
                              <strong>Date/time: </strong>{new Date(i.dateTime).toLocaleString()}
                            </li>
                          );
                        }
                        else {
                          if (i.more) {
                            return (
                              <li key={`nbdr_r_${pipeName}_more`}>
                                <em>{`[... ${i.more} more readings]`}</em>
                              </li>
                            );
                          }
                        }
                      })}
                    </ul>
                  </li>
                )}
              </ul>
            </>}
          </Alert>}
          {(preselectedBoreholesWithoutData.length > 0 || preselectedPipesWithoutData.length > 0) && <Alert severity="info" title="More information may be available">
            Please visit the following <strong>EKFB Groundwater Risk App (GWRA)</strong> link for detailed georeferenced excel based hydrograph attachments: <br />
            <strong><a onClick={handleAGOLClick} target="_blank" href={`${config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT}(${preselectedBoreholes?.join(',')})`}>{config.ESRI_WEB_APP_WATER_LEVEL_LAYER_NAME}</a></strong>.
          </Alert>}
        {/* </CardContent>
      </Card> */}
    </Box>
  );
};
