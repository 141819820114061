import {
  Card,
  CardContent,
  Typography
} from "@mui/material";
import {
  flowDataNearestRainfallStation,
} from "../../state/recoil";
import {
  useRecoilValue,
} from "recoil";

export const RainfallStationData = () => {
  const nearestStation = useRecoilValue(flowDataNearestRainfallStation);

  if (nearestStation) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">EA Rain Gauge Details</Typography>
          <Typography>
            <strong>EA ID:</strong> {nearestStation.stationReference}
            <br />
            <strong>Lat:</strong> {nearestStation.lat}
            <br />
            <strong>Long:</strong> {nearestStation.long}
            <br />
            <strong>
              Distance to selected water level monitoring point:
            </strong>{" "}
            {(nearestStation.distance / 1000).toFixed(3)} km
            <br />
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    return <></>;
  }
};
