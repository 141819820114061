import {
  Box,
} from "@mui/material";
import {
  chemicalBoreholesState,
  chemistryDataState,
  determinantListState,
  selectedBoreholesChemistryState,
  selectedDeterminantSelector,
} from "../../state/recoil";
import {
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";

import { Alert } from "../../components/alert/alert";
import config from "../../lib/config";
import logUserActivity from "../../common/user-activity-logging";
import { useSearchParams } from "react-router-dom";

export const Alerts = () => {
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState).getValue() || [];
  const selectedDeterminant = useRecoilValue(selectedDeterminantSelector);
  const chemistryData = useRecoilValue(chemistryDataState);
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const determinants = useRecoilValueLoadable(determinantListState(selectedBoreholes.join(",")));
  const missingBoreholes = selectedBoreholes.filter(
    (borehole) => selectedDeterminant && selectedDeterminant.length > 0 && !chemistryData.find((dataset) => dataset.name === borehole)
  );
  const hasUngroupedData = chemistryData.some((dataset) =>
    dataset.data.some((data) => !data.is_grouped)
  );
  const [searchParams] = useSearchParams();
  const preselectedBoreholes = searchParams.get('boreholes')?.split(',') || [];
  const preselectedBoreholesWithoutData = preselectedBoreholes.filter(preselectedBorehole => !boreholes.find(borehole => preselectedBorehole === borehole));
  const preselectedDeterminants = searchParams.get('determinants')?.split(',') || [];
  const preselectedDeterminantsWithoutData = preselectedDeterminants.filter(preselectedDeterminant => !determinants.getValue()?.find(determinant => preselectedDeterminant === determinant));

  const handleAGOLClick = () => {
    logUserActivity({
      action: 'CLICKED',
      component: 'ALERT_AGOL_LINK_CHEMISTRY',
      data: config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT + preselectedBoreholesWithoutData.join(','),
    });
  }

  const hasAlerts =
    missingBoreholes?.length > 0 || hasUngroupedData || preselectedBoreholesWithoutData?.length > 0 || preselectedDeterminantsWithoutData && preselectedDeterminantsWithoutData?.length > 0;

  return (
    hasAlerts && (
      <Box marginBottom={3} marginTop={3}>
        {/* <Card>
          <CardContent> */}
            {missingBoreholes?.length > 0 && <Alert>
              For the selected determinant, no information is available for the following monitoring locations. <strong>{missingBoreholes.join(", ")}</strong>.
            </Alert>}
            {hasUngroupedData && <Alert title="Warning - ungrouped series data points">
              Due to inconsistently reported determinant naming and/or units within incoming datasets, some data points in series (● solid circles) values were not converted to primary units.
            </Alert>}
            {preselectedBoreholesWithoutData?.length > 0 && <Alert title={`No information for available monitoring ${preselectedBoreholesWithoutData.length > 1 ? 'points' : 'point'}`}>
              No graphable water chemistry data is available for <strong>{preselectedBoreholesWithoutData.join(', ')}</strong>. <br />
              It may be that no monitoring data is available for this point, or that non-numeric readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
            </Alert>}
            {preselectedDeterminantsWithoutData?.length > 0 && <Alert title={`No information for specified ${preselectedDeterminantsWithoutData.length > 1 ? 'determinants' : 'determinant'}`}>
              No determinant preselection can be completed for <strong>{preselectedDeterminantsWithoutData.join(', ')}</strong>. <br />
              It may be that no monitoring data is available for this determinant, or that non-numeric readings exist that cannot be plotted, or data may be available under a different determinant name (please see the dropdown menu above).
            </Alert>}
            {(preselectedBoreholesWithoutData?.length > 0 || preselectedDeterminantsWithoutData?.length > 0)  && <Alert severity="info" title="More information may be available">
              Please visit the following <strong>EKFB Groundwater Risk App (GWRA)</strong> link for detailed georeferenced excel based chemograph attachments: <br />
              <strong><a onClick={handleAGOLClick} target="_blank" href={`${config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT}(${preselectedBoreholes.join(',')})`}>{config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_NAME}</a></strong>.
            </Alert>}
          {/* </CardContent>
        </Card> */}
      </Box>
    )
  );
};
