import "./app.css";

// import * as Sentry from "@sentry/react";

import { useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';

import logUserActivity from "./common/user-activity-logging";
import { userRoles } from "./lib/user-roles";

import Disclaimer from "./components/disclaimer";
import Loading from "./components/loadingScreen";
import ProtectedRoute from "./components/protected-route/protected-route";
import { useAuth } from "./components/auth-provider/auth-provider";

import useUnload from "./hooks/useUnload";

import PageLayout from "./layouts/page-layout";

import WaterLevels from "./pages/waterLevels";
import FlowData from "./pages/flowData";
import Chemicals from "./pages/chemicals";
import CCChemicals from "./pages/cc-chemicals";
import LiveWaterLevels from './pages/liveWaterLevels';
import { FAQ } from './pages/faq';
import Login from "./pages/login";
import { Logout } from "./pages/logout";
import PasswordChangePage from "./pages/password-change";
import PasswordResetPage from "./pages/password-reset";
import RegisterPage from "./pages/register";
import UnlockPage from "./pages/unlock";
import UserAdminPage from "./pages/admin/user/user";

import { MaintenancePage } from "./pages/maintenancePage";

import { useHTTPRedirect } from "./hooks/useHTTPRedirect";

import config from './lib/config';
import { useRecoilValueLoadable } from "recoil";

import { frontEndSettingsState } from "./state/recoil";

const App = () => {
  const { loading, user } = useAuth();
  const frontEndSettings = useRecoilValueLoadable(frontEndSettingsState);

  useUnload(e => {
    logUserActivity({ action: 'APP_CLOSE' })
    e.preventDefault();
  });

  // HTTP->HTTPS redirect hook
  useHTTPRedirect();

  // Set default route based on permissions (rewrite for clarity...)
  let defaultRoute;
  switch (true) {

    case user?.id && user?.allow_waterlevel:
      defaultRoute = 'waterLevels';
      break;

    case user?.id && user?.allow_flowdata:
      defaultRoute = 'flowData';
      break;

    case user?.id && user?.allow_chemistry:
      defaultRoute = 'waterChemistry';
      break;

    case user?.id:
      defaultRoute = 'logout';
      break;

    default:
      defaultRoute = 'login';
  }

  const renderContent = () => {
    switch (true) {
      case frontEndSettings?.contents?.maintenanceMode.active:
        return <MaintenancePage />;
        break;

      case !frontEndSettings?.contents?.maintenanceMode.active && !loading:
        return (
          <BrowserRouter>
            <Routes>

              <Route path="login" element={<Login />} />

              <Route
                path="logout"
                element={
                  <ProtectedRoute>
                    <Logout />
                  </ProtectedRoute>
                }
              />

              <Route
                path="register/:id"
                element={
                  <RegisterPage />
                }
              />

              <Route
                path="password-reset"
                element={
                  <PasswordResetPage />
                }
              />

              <Route
                path="password-change/:id"
                element={
                  <PasswordChangePage />
                }
              />

              <Route
                path="unlock/:id"
                element={
                  <UnlockPage />
                }
              />

              <Route
                path="waterLevels"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<WaterLevels />}
                      title={"Water Levels"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="flowData"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<FlowData />}
                      title={'Flow Data'}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="waterChemistry"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<Chemicals />}
                      title={"Water Chemistry"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="chemconnect"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<CCChemicals />}
                      title={"Chemconnect Data"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="interimWaterLevels"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<LiveWaterLevels />}
                      title={"Interim Water Levels"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="faq"
                element={
                  <ProtectedRoute>
                    <PageLayout
                      page={<FAQ />}
                      title={"Frequently asked questions"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />


              <Route
                path="admin/user"
                element={
                  <ProtectedRoute roles={[userRoles.admin, userRoles.platform_admin]}>
                    <PageLayout
                      page={<UserAdminPage />}
                      title={"User administration"}
                    ></PageLayout>
                  </ProtectedRoute>
                }
              />

              <Route
                path="*"
                element={<Navigate to={defaultRoute}></Navigate>}
              />

            </Routes>
          </BrowserRouter>
        );
        break;

      default:
        return <Loading />;
    }
  };

  return <Box>
    <CssVarsProvider>
      <Disclaimer></Disclaimer>
      <Container>
        <Grid gap={3} paddingTop={10} justifyContent="center" container>
          <Grid xs={12} item>
            {frontEndSettings.state === 'hasValue' && renderContent()}
            {frontEndSettings.state !== 'hasValue' && <Loading />}
          </Grid>
        </Grid>
      </Container>
    </CssVarsProvider>
  </Box>
}

// export default Sentry.withProfiler(App);
export default App;
